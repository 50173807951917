import React, { useState } from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import {
  breakpoints, Icon, useMediaQuery,
} from '@openedx/paragon';
import SelectableBox from '@openedx/paragon/dist/SelectableBox';
import PropTypes from 'prop-types';

import './index.scss';

const SelectableBoxField = (props) => {
  const { formatMessage } = useIntl();
  const {
    label, name, fieldMessages, options, onSelect, keepTwoColsInExtraSmall,
  } = props;
  const [selectedOption, setSelectedOption] = useState('');

  const isExtraSmall = useMediaQuery({ maxWidth: breakpoints.extraSmall.maxWidth });

  const handleClick = (event) => {
    setSelectedOption(event.target.value);
    onSelect(event);
  };

  return (
    <>
      <h2
        id={`${name}-selectable-question`}
        className="mb-3.5 mb-lg-4.5 px-1 field__question-heading"
      >
        {label}
      </h2>
      <SelectableBox.Set
        role="group"
        value={selectedOption}
        onChange={handleClick}
        name={name}
        columns={(isExtraSmall && !keepTwoColsInExtraSmall) ? 1 : 2}
        ariaLabel={`${name} field selection`}
        ariaLabelledby={`${name}-selectable-question`}
        className="overflow-auto p-1"
      >
        {options.map((option) => (
          <SelectableBox
            role="link"
            data-testid="link-button"
            key={option?.label}
            value={option?.label}
            className="d-flex flex-column justify-content-center align-items-center text-center"
            aria-label={option?.label}
          >
            {option?.icon && (<Icon src={option.icon} />)}
            <div className="h5">{formatMessage(fieldMessages[`${name}.option.${option?.label}`])}</div>
          </SelectableBox>
        ))}
      </SelectableBox.Set>
    </>
  );
};

SelectableBoxField.propTypes = {
  fieldMessages: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  keepTwoColsInExtraSmall: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

SelectableBoxField.defaultProps = {
  fieldMessages: {},
  keepTwoColsInExtraSmall: false,
};

export default SelectableBoxField;
