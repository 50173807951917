import { useEffect, useState } from 'react';

import algoliasearchHelper from 'algoliasearch-helper';

import initializeSearchClient from '../../../../data/algolia';
import { parseSubjectsFromAlgoliaResults } from '../quiz-utils';

const PRODUCT_INDEX = 'product';
const SUBJECT_FACET = 'subject';

const useSubjectsList = () => {
  const [subjectsList, setSubjectsList] = useState([]);
  const [subjectsLoading, setSubjectsLoading] = useState(true);

  useEffect(() => {
    const algoliaSearchParams = {
      facets: [SUBJECT_FACET],
    };

    const searchClient = initializeSearchClient();
    const searchHelper = algoliasearchHelper(
      searchClient,
      PRODUCT_INDEX,
      algoliaSearchParams,
    );

    const searchIndex = () => {
      setSubjectsLoading(true);
      searchHelper.search();
    };

    searchIndex();

    searchHelper.on('result', ({ results }) => {
      setSubjectsList(parseSubjectsFromAlgoliaResults(results.getFacetValues(SUBJECT_FACET)));
      setSubjectsLoading(false);
    });

    searchHelper.on('error', () => setSubjectsLoading(false));
  }, []);

  return {
    subjectsList,
    subjectsLoading,
  };
};

export default useSubjectsList;
