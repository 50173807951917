const NUM_OF_STEPS = 7;
const questions = {
  subject: {
    name: 'subject',
    label: 'subject',
    text: 'What field are you interested in?',
    type: 'select',
  },
  skillLevel: {
    name: 'skillLevel',
    label: 'current_skill_level',
    text: 'What is your current level in your selected field?',
    type: 'select',
    options: [
      {
        label: 'no-education-and-no-work-experience',
      },
      {
        label: 'has-education-and-no-work-experience',
      },
      {
        label: 'no-education-and-has-work-experience',
      },
      {
        label: 'has-education-and-has-work-experience',
      },
    ],
  },
  levelOfEducation: {
    name: 'levelOfEducation',
    label: 'level_of_education',
    text: 'What is your highest level of education completed? This will help us show you offerings that you qualify for.',
    type: 'select',
    options: [
      {
        label: 'none',
      },
      {
        label: 'jhs',
      },
      {
        label: 'hs',
      },
      {
        label: 'a',
      },
      {
        label: 'b',
      },
      {
        label: 'm',
      },
      {
        label: 'p',
      },
      {
        label: 'other',
      },
    ],
  },
  learningType: {
    name: 'learningType',
    label: 'learning_type',
    text: 'What type of experience are you interested in? This will help us hone in on our most relevant options to your goals.',
    type: 'select',
    options: [
      {
        label: 'Courses',
      },
      {
        label: 'Boot Camps',
      },
      {
        label: 'Degrees',
      },
      {
        label: 'Executive Education',
      },
      {
        label: 'unsure',
      },
    ],
  },
  workExperience: {
    name: 'workExperience',
    label: 'work_experience',
    text: 'How many years of work experience do you have?',
    type: 'select',
    options: [
      {
        label: '0yrs',
      },
      {
        label: '1-5yrs',
      },
      {
        label: '6-10yrs',
      },
      {
        label: '11-15yrs',
      },
      {
        label: '16-20yrs',
      },
      {
        label: '20+yrs',
      },
    ],
  },
};

export { NUM_OF_STEPS, questions };
