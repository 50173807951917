import React from 'react';

import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';

import './index.scss';
import messages from './messages';
import headerImage from '../../../assets/header/headerImage.png';

const BannerImageLayout = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="py-3 px-4 align-items-center d-flex position-relative header">
      <div className="w-100 h-100 bg-primary-500 position-absolute header__bg">
        <img src={headerImage} alt="" className="h-100" />
      </div>
      <div className="mie-5 position-relative">
        <div className="d-flex align-items-center">
          <img src={getConfig().LOGO_WHITE_URL} alt="edx-logo" className="d-flex align-items-center justify-content-center flex-shrink-0 header__logo" />
          <div className="ml-4 mr-2 header__vertical-line" />
          <div className="d-inline-flex flex-column align-items-start">
            <h1 className="text-accent-b">
              {formatMessage(messages.quizMatchHeaderTitle)}
            </h1>
            <h2 className="text-white">
              {formatMessage(messages.quizMatchHeaderSubheading)}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerImageLayout;
