import { LETTER_REGEX, NUMBER_REGEX } from './constants';
import messages from '../messages';

const validatePassword = (value, formatMessage) => {
  let fieldError = '';

  if (!value || !LETTER_REGEX.test(value) || !NUMBER_REGEX.test(value) || value.length < 8) {
    fieldError = formatMessage(messages.passwordValidationMessage);
  }

  return fieldError;
};

export default validatePassword;
