import React from 'react';

import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Hyperlink } from '@openedx/paragon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import messages from './messages';

const TermsOfServiceAndHonorCode = ({ useCampaignColors }) => {
  const { formatMessage } = useIntl();
  const tosMessageValues = {
    platformName: getConfig().SITE_NAME,
    tosAndHonorCode: (
      <Hyperlink destination={getConfig().TERMS_OF_SERVICE_URL || '#'} target="_blank" showLaunchIcon={false}>
        {formatMessage(messages.termsOfServiceHyperlink)}
      </Hyperlink>
    ),
    privacyPolicy: (
      <Hyperlink destination={getConfig().PRIVACY_POLICY_URL || '#'} target="_blank" showLaunchIcon={false}>
        {formatMessage(messages.privacyPolicy)}
      </Hyperlink>
    ),
  };
  return (
    <p
      className={classNames(
        'micro text-muted',
        { 'campaign-link': useCampaignColors },
      )}
    >
      {formatMessage(messages.termOfServices, { ...tosMessageValues })}
    </p>
  );
};

TermsOfServiceAndHonorCode.propTypes = {
  useCampaignColors: PropTypes.bool,
};

TermsOfServiceAndHonorCode.defaultProps = {
  useCampaignColors: false,
};

export default TermsOfServiceAndHonorCode;
