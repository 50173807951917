import messages from '../messages';

const validateUsername = (value, formatMessage, numberOfSuggestions) => {
  let fieldError = '';

  if (numberOfSuggestions !== 0) {
    return fieldError;
  }
  if (!value || value.length <= 1 || value.length > 30) {
    fieldError = formatMessage(messages['username.validation.message']);
  } else if (!value.match(/^[a-zA-Z0-9_-]*$/i)) {
    fieldError = formatMessage(messages['username.format.validation.message']);
  }

  return fieldError;
};

export default validateUsername;
