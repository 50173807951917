import React from 'react';

import { FormattedMessage } from '@edx/frontend-platform/i18n';
import { ProgressBar } from '@openedx/paragon';
import PropTypes from 'prop-types';

import { STEP1 } from '../../../../data/constants';

const progressBarDescription = (currentStep, totalSteps) => (
  <FormattedMessage
    id="progress.bar.description"
    defaultMessage="Quiz progress: step {currentStep} of {totalSteps}"
    values={{
      currentStep,
      totalSteps,
    }}
  />
);

const QuizProgressBar = ({ currentStep, totalSteps }) => (
  <div className="pb-3 pb-lg-5 px-1">
    <p className="x-small m-0">{progressBarDescription(currentStep, totalSteps)}</p>
    <ProgressBar now={currentStep} min={STEP1} max={totalSteps} variant="success" />
  </div>
);

QuizProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default QuizProgressBar;
