import React, { useEffect, useState } from 'react';

import { getConfig } from '@edx/frontend-platform';
import { FormattedMessage, useIntl } from '@edx/frontend-platform/i18n';
import { logInfo } from '@edx/frontend-platform/logging';
import { Spinner } from '@openedx/paragon';
import { QueryClient, QueryClientProvider } from 'react-query';

import messages from './components/quizes/messages';
import QuizV1 from './components/quizes/QuizV1';
import QuizV2 from './components/quizes/QuizV2';
import './index.scss';
import useSubjectsList from './data/hooks/useSubjectsList';
import { getQueryParams } from './data/utils/quiz-utils';
import {
  QUIZ_1_VARIATION,
  QUIZ_2_VARIATION,
  VALID_ORGANIC_TRAFFIC_SOURCES,
  VALID_VARIATIONS,
} from '../../data/constants';
import {
  activateQuizMatchIteration1Experiment, getQuizMatchIteration1ExperimentVariation, getRandomQuizMatchVariation,
} from '../../data/optimizely';

const queryClient = new QueryClient();

const QuizContainer = () => {
  const { formatMessage } = useIntl();

  const [quizVariation, setQuizVariation] = useState('');
  const [experiment, setExperiment] = useState(getConfig().QUIZ_MATCH_EXPERIMENT_ID);
  const [isOrganicTraffic, setIsOrganicTraffic] = useState(false);
  const { subjectsList, subjectsLoading } = useSubjectsList();

  const queryParams = getQueryParams();
  const valueProp = (
    <FormattedMessage
      {...messages.learnerGuideMessage}
      values={{
        educationExpertise: <b>{formatMessage(messages.educationExpertiseLabel)}</b>,
        laborMarketData: <b>{formatMessage(messages.laborMarketDataLabel)}</b>,
        reachYourLearningAndProfessionalGoals:
  <b>{formatMessage(messages.reachYourLearningAndProfessionalGoalsLabel)}</b>,
        growChangeOrLearn: <b>{formatMessage(messages.growChangeOrLearnLabel)}</b>,
        relevantCourseOrProgram: <b>{formatMessage(messages.relevantCourseOrProgramLabel)}</b>,
      }}
    />
  );

  useEffect(() => {
    let timer500ms = null;
    let timer3000ms = null;
    let variationObtained = false;

    const checkVariation500ms = () => {
      const variation = getQuizMatchIteration1ExperimentVariation();
      if (variation) {
        setQuizVariation(variation);
        variationObtained = true;
        clearTimeout(timer3000ms);
      }
    };

    const checkVariation3000ms = () => {
      if (!variationObtained) {
        const variation = getQuizMatchIteration1ExperimentVariation();
        if (variation) {
          logInfo(`Quiz Match Variation=${variation} found at 3000ms`);
          setQuizVariation(variation);
          variationObtained = true;
        } else {
          setQuizVariation(getRandomQuizMatchVariation());
          setExperiment('');
        }
      }
    };

    // If the user is coming from organic traffic source, set the variation
    // available in the query param if its valid otherwise default to random
    // variation.
    const setOrganicTrafficExperiment = () => {
      if (VALID_VARIATIONS.includes(queryParams.variation)) {
        setIsOrganicTraffic(true);
        setQuizVariation(queryParams.variation);
        setExperiment(queryParams.experiment || '');
      } else {
        setQuizVariation(getRandomQuizMatchVariation());
        setExperiment('');
      }
    };

    if (VALID_ORGANIC_TRAFFIC_SOURCES.includes(queryParams.source)) {
      setOrganicTrafficExperiment();
    } else {
      activateQuizMatchIteration1Experiment();
      timer500ms = setTimeout(checkVariation500ms, 500);
      timer3000ms = setTimeout(checkVariation3000ms, 3000);
    }

    return () => {
      clearTimeout(timer500ms);
      clearTimeout(timer3000ms);
    };
  }, [queryParams.source, queryParams.variation, queryParams.experiment]);

  const renderQuiz1 = quizVariation === QUIZ_1_VARIATION;
  const renderQuiz2 = quizVariation === QUIZ_2_VARIATION;
  const isLoading = quizVariation === '';

  return (
    <QueryClientProvider client={queryClient}>
      {isLoading && <Spinner animation="border" className="spinner" />}
      {renderQuiz1 && (
        <QuizV1
          quizValueProp={valueProp}
          subjectsList={subjectsList}
          experiment={experiment}
          isLoading={subjectsLoading}
          isOrganicTraffic={isOrganicTraffic}
        />
      )}
      {renderQuiz2 && (
        <QuizV2
          quizValueProp={valueProp}
          subjectsList={subjectsList}
          experiment={experiment}
          isLoading={subjectsLoading}
          isOrganicTraffic={isOrganicTraffic}
        />
      )}
    </QueryClientProvider>
  );
};

export default QuizContainer;
