import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  termsOfServiceHyperlink: {
    id: 'terms.of.service.and.honor.code',
    defaultMessage: 'Terms of Service and Honor Code',
    description: 'Text for the hyperlink that redirects user to terms of service and honor code',
  },
  termOfServices: {
    id: 'register.page.terms.of.service',
    defaultMessage: 'By creating an account, you agree to the {tosAndHonorCode} and you acknowledge that {platformName} and each Member process your personal data in accordance with the {privacyPolicy}.',
    description: 'Text that appears on registration form stating terms of service. It is a legal document that users must agree to.',
  },
  privacyPolicy: {
    id: 'privacy.policy',
    defaultMessage: 'Privacy Policy',
    description: 'Text for the hyperlink that redirects user to privacy policy',
  },
});

export default messages;
