import React from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import {
  Button, StatefulButton, Stepper,
} from '@openedx/paragon';
import PropTypes from 'prop-types';

import messages from './messages';
import { DEFAULT_STATE, PENDING_STATE, STEP1 } from '../../../../data/constants';
import { trackQuizMatchPreviousButtonClicked } from '../../../../data/track';

import './index.scss';

const StepperFooter = (props) => {
  const { formatMessage } = useIntl();
  const {
    currentStep,
    disabled,
    onSubmit,
    setCurrentStep,
    onNext,
    numberOfSteps,
  } = props;

  const nextStepHandle = () => {
    if (currentStep < numberOfSteps) {
      onNext(currentStep);
    }
  };

  const backStepHandle = () => {
    trackQuizMatchPreviousButtonClicked({ position: currentStep });
    if (currentStep > STEP1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const footerSteps = () => {
    const steps = [];
    [...Array(numberOfSteps)].map((_, key) => {
      const stepNumber = key + 1;
      return (
        steps.push(
          <Stepper.ActionRow eventKey={`step${stepNumber}`} key={`step${stepNumber}`}>
            {stepNumber !== STEP1 && (
              <>
                <Button
                  variant="outline-primary"
                  disabled={disabled}
                  onClick={backStepHandle}
                >
                  {formatMessage(messages.previousStepButton)}
                </Button>
                <Stepper.ActionRow.Spacer />
              </>
            )}
            {stepNumber !== numberOfSteps && (
              <Button onClick={nextStepHandle}>
                {formatMessage(messages.nextStepButton)}
              </Button>
            )}
            {stepNumber === numberOfSteps && (
              <StatefulButton
                name="fetch-recommendations"
                type="submit"
                state={disabled ? PENDING_STATE : DEFAULT_STATE}
                labels={{
                  default: formatMessage(messages.showMeMyRecommendationsButton),
                  pending: formatMessage(messages.generatingYourRecommendations),
                }}
                onClick={onSubmit}
                onMouseDown={(e) => e.preventDefault()}
              />
            )}
          </Stepper.ActionRow>,
        )
      );
    });
    return steps;
  };

  return (
    <div className="py-3 px-4 w-100 border-top bg-white">
      {footerSteps()}
    </div>
  );
};

StepperFooter.propTypes = {
  currentStep: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default StepperFooter;
