import { getConfig } from '@edx/frontend-platform';

// Header component constants
export const HEADER_PRIMARY_GRADIENT = 'primary-gradient';
export const HEADER_LIGHT = 'white';

export const STEP1 = 1;
export const STEP2 = 2;
export const STEP3 = 3;
export const STEP4 = 4;
export const STEP5 = 5;
export const STEP6 = 6;
export const STEP7 = 7;

// Common states
export const DEFAULT_STATE = 'default';
export const PENDING_STATE = 'pending';

export const REGISTER_ENDPOINT = `${getConfig().LMS_BASE_URL}/api/user/v2/account/registration/`;
export const GET_USERNAME_SUGGESTIONS_ENDPOINT = `${getConfig().LMS_BASE_URL}/api/user/v1/validation/registration`;
export const DEFAULT_REDIRECT_URL = `${getConfig().MARKETING_SITE_BASE_URL}`;

export const COUNTRY_CODE_KEY = 'code';
export const COUNTRY_DISPLAY_KEY = 'name';

export const QUIZ1_FIELD_MAPPING = {
  STEP1: ['subject'],
  STEP2: ['skillLevel'],
  STEP3: ['learningType'],
  STEP4: ['levelOfEducation'],
  STEP5: ['workExperience'],
  STEP6: ['name', 'country'],
  STEP7: ['username', 'email', 'password'],
};

export const QUIZ2_FIELD_MAPPING = {
  STEP1: ['subject'],
  STEP2: ['learningGoal'],
  STEP3: ['levelOfEducation'],
  STEP4: ['workExperience'],
  STEP5: ['interestedInCertificate'],
  STEP6: ['name', 'country'],
  STEP7: ['username', 'email', 'password'],
};

export const QUIZ_FIELD_MAPPING_ITERATION_2 = {
  STEP1: ['subject'],
  STEP2: ['skillLevel'],
  STEP3: ['levelOfEducation'],
  STEP4: ['learningType'],
  STEP5: ['workExperience'],
  STEP6: ['name', 'country'],
  STEP7: ['username', 'email', 'password'],
};

export const MATCH_QUIZ_ROUTE = '/match-quiz';
export const MATCH_QUIZ_ROUTE_V2 = '/v2/match-quiz';
export const PAGE_NOT_FOUND = '/notfound';

export const BACKEND_ERRORS_KEYS = ['email', 'username', 'password'];

export const QUIZ_1_VARIATION = 'Quiz1';

export const QUIZ_2_VARIATION = 'Quiz2';

export const VALID_ORGANIC_TRAFFIC_SOURCES = ['https://www.edx.org/'];

export const VALID_VARIATIONS = [QUIZ_1_VARIATION, QUIZ_2_VARIATION];

export const CURRENT_ITERATION = 1;
export const ITERATION_2 = 2;

export const LEARNING_TYPES_FOR_PARAMS = ['course', 'program', 'degree-program', 'boot-camp', 'executive-education'];

export const OPTIONS_WITH_DEGREE_PROGRAMS = ['Boot Camps', 'Degrees', 'unsure'];

// regex more focused towards url matching
export const URL_REGEX = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi; // eslint-disable-line no-useless-escape

// regex for html tags
export const HTML_REGEX = /<|>/u;

// regex from backend
export const INVALID_NAME_REGEX = /https?:\/\/(?:[-\w.]|(?:%[\da-fA-F]{2}))*/g;
