import React from 'react';

import './index.scss';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Button } from '@openedx/paragon';
import PropTypes from 'prop-types';

import messages from '../../data/messages';

const TopBannerLayout = ({
  backStepHandler, heading, topBannerImage, stepsImageText,
}) => {
  const { formatMessage } = useIntl();

  if (heading) {
    return (
      <div className="small-layout top-banner w-100 bg-light-200 d-flex align-items-center">
        <h1 className="left-banner__heading text-primary-500 mb-0 px-md-3 px-sm-4 px-4 py-md-4.5 py-sm-3.5 py-4">
          {heading}
        </h1>
      </div>
    );
  }

  return (
    <div className="small-layout top-banner w-100">
      <img
        className="top-banner__background-img w-100"
        src={topBannerImage}
        alt={stepsImageText}
      />
      {backStepHandler && (
        <Button className="quiz__back-btn" size="sm" variant="outline-dark" onClick={backStepHandler}>
          {formatMessage(messages.backStepButton)}
        </Button>
      )}
    </div>
  );
};

TopBannerLayout.propTypes = {
  topBannerImage: PropTypes.string.isRequired,
  heading: PropTypes.element,
  backStepHandler: PropTypes.func,
  stepsImageText: PropTypes.string.isRequired,
};

TopBannerLayout.defaultProps = {
  backStepHandler: null,
  heading: null,
};

export default TopBannerLayout;
