import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  // ***** Subject Field *****
  subjectFieldLabel: {
    id: 'subject.field.label',
    defaultMessage: 'What field are you interested in?',
    description: 'Label for subject field',
  },
  subjectFieldPlaceholder: {
    id: 'subject.field.placeholder',
    defaultMessage: 'Search by subject',
    description: 'Placeholder for subject field',
  },
  subjectEmptyFieldError: {
    id: 'subject.empty.field.error',
    defaultMessage: 'Please select a subject',
    description: 'Error for subject field',
  },
  subjectInvalidValueFieldError: {
    id: 'subject.invalid.value.field.error',
    defaultMessage: 'No results for "{value}". Check spelling or select one of the available dropdown options',
    description: 'Error for invalid value of subject field',
  },
  'subject.option.Business & Management': {
    id: 'subject.option.Business & Management',
    defaultMessage: 'Business & Management',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Computer Science': {
    id: 'subject.option.Computer Science',
    defaultMessage: 'Computer Science',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Engineering': {
    id: 'subject.option.Engineering',
    defaultMessage: 'Engineering',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Social Sciences': {
    id: 'subject.option.Social Sciences',
    defaultMessage: 'Social Sciences',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Data Analysis & Statistics': {
    id: 'subject.option.Data Analysis & Statistics',
    defaultMessage: 'Data Analysis & Statistics',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Economics & Finance': {
    id: 'subject.option.Economics & Finance',
    defaultMessage: 'Economics & Finance',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Communication': {
    id: 'subject.option.Communication',
    defaultMessage: 'Communication',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Humanities': {
    id: 'subject.option.Humanities',
    defaultMessage: 'Humanities',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Science': {
    id: 'subject.option.Science',
    defaultMessage: 'Science',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Environmental Studies': {
    id: 'subject.option.Environmental Studies',
    defaultMessage: 'Environmental Studies',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Medicine': {
    id: 'subject.option.Medicine',
    defaultMessage: 'Medicine',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Biology & Life Sciences': {
    id: 'subject.option.Biology & Life Sciences',
    defaultMessage: 'Biology & Life Sciences',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Health & Safety': {
    id: 'subject.option.Health & Safety',
    defaultMessage: 'Health & Safety',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Education & Teacher Training': {
    id: 'subject.option.Education & Teacher Training',
    defaultMessage: 'Education & Teacher Training',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Art & Culture': {
    id: 'subject.option.Art & Culture',
    defaultMessage: 'Art & Culture',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Math': {
    id: 'subject.option.Math',
    defaultMessage: 'Math',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.History': {
    id: 'subject.option.History',
    defaultMessage: 'History',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Design': {
    id: 'subject.option.Design',
    defaultMessage: 'Design',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Physics': {
    id: 'subject.option.Physics',
    defaultMessage: 'Physics',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Energy & Earth Sciences': {
    id: 'subject.option.Energy & Earth Sciences',
    defaultMessage: 'Energy & Earth Sciences',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Law': {
    id: 'subject.option.Law',
    defaultMessage: 'Law',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Philosophy & Ethics': {
    id: 'subject.option.Philosophy & Ethics',
    defaultMessage: 'Philosophy & Ethics',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Language': {
    id: 'subject.option.Language',
    defaultMessage: 'Language',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Electronics': {
    id: 'subject.option.Electronics',
    defaultMessage: 'Electronics',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Food & Nutrition': {
    id: 'subject.option.Food & Nutrition',
    defaultMessage: 'Food & Nutrition',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Architecture': {
    id: 'subject.option.Architecture',
    defaultMessage: 'Architecture',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Chemistry': {
    id: 'subject.option.Chemistry',
    defaultMessage: 'Chemistry',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Literature': {
    id: 'subject.option.Literature',
    defaultMessage: 'Literature',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Ethics': {
    id: 'subject.option.Ethics',
    defaultMessage: 'Ethics',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Music': {
    id: 'subject.option.Music',
    defaultMessage: 'Music',
    description: 'Option for the subject dropdown field',
  },
  'subject.option.Philanthropy': {
    id: 'subject.option.Philanthropy',
    defaultMessage: 'Philanthropy',
    description: 'Option for the subject dropdown field',
  },
  // ***** Skill Level Field
  skillLevelFieldLabel: {
    id: 'skillLevel.field.label',
    defaultMessage: 'What is your current level in your selected field?',
    description: 'Label for field that asks learner about their skill level',
  },
  skillLevelFieldPlaceholder: {
    id: 'skillLevel.field.placeholder',
    defaultMessage: 'Select a skill level',
    description: 'Placeholder for field that asks learner about their skill level',
  },
  skillLevelEmptyFieldError: {
    id: 'skillLevel.empty.field.error',
    defaultMessage: 'Please select your skill level',
    description: 'Error for skillLevel field',
  },
  'skillLevel.option.no-education-and-no-work-experience': {
    id: 'skillLevel.option.no-education-and-no-work-experience',
    defaultMessage: 'I have no education and no work experience in the field',
    description: 'Option for field that asks learner about their skill level',
  },
  'skillLevel.option.has-education-and-no-work-experience': {
    id: 'skillLevel.option.has-education-and-no-work-experience',
    defaultMessage: 'I have education but no work experience in the field',
    description: 'Option for field that asks learner about their skill level',
  },
  'skillLevel.option.no-education-and-has-work-experience': {
    id: 'skillLevel.option.no-education-and-has-work-experience',
    defaultMessage: 'I have work experience but no education in the field',
    description: 'Option for field that asks learner about their skill level',
  },
  'skillLevel.option.has-education-and-has-work-experience': {
    id: 'skillLevel.option.has-education-and-has-work-experience',
    defaultMessage: 'I have work experience and education in the field',
    description: 'Option for field that asks learner about their skill level',
  },
  // ***** Interested in Certificate Field *****
  interestedInCertificateFieldLabel: {
    id: 'interestedInCertificate.field.label',
    defaultMessage: 'What kind of certificate or degree upon completion would benefit you the most?',
    description: 'Label for field asking learners if they are interested in earning a certificate',
  },
  interestedInCertificateEmptyFieldError: {
    id: 'interestedInCertificate.empty.field.error',
    defaultMessage: 'Please select your certificate requirements',
    description: 'Error for interestedInCertificate field',
  },
  'interestedInCertificate.option.no-certificate': {
    id: 'interestedInCertificate.option.no-certificate',
    defaultMessage: 'I don’t need proof of completion',
    description: 'Option for field asking learners if they are interested in earning a certificate',
  },
  'interestedInCertificate.option.certificate': {
    id: 'interestedInCertificate.option.certificate',
    defaultMessage: 'I want a certificate from a top institute',
    description: 'Option for field asking learners if they are interested in earning a certificate',
  },
  'interestedInCertificate.option.accredited-degree': {
    id: 'interestedInCertificate.option.accredited-degree',
    defaultMessage: 'I want an accredited degree',
    description: 'Option for field asking learners if they are interested in earning a certificate',
  },
  'interestedInCertificate.option.unsure': {
    id: 'interestedInCertificate.option.unsure',
    defaultMessage: 'Unsure',
    description: 'Option for field asking learners if they are interested in earning a certificate',
  },
  // ***** Learning Type Field *****
  learningTypeFieldLabel: {
    id: 'learningType.field.label',
    defaultMessage: 'What type of experience are you interested in? This will help us hone '
                    + 'in on our most relevant options to your goals.',
    description: 'Label for learning type field',
  },
  learningTypeFieldPlaceholder: {
    id: 'learningType.field.placeholder',
    defaultMessage: 'Select an offering',
    description: 'Placeholder for learning type field',
  },
  learningTypeEmptyFieldError: {
    id: 'learningType.empty.field.error',
    defaultMessage: 'Please select an offering',
    description: 'Error for learningType field',
  },
  'learningType.option.Courses': {
    id: 'learningType.option.Courses',
    defaultMessage: 'Courses',
    description: 'Option for learning type dropdown field',
  },
  'learningType.option.Programs': {
    id: 'learningType.option.Programs',
    defaultMessage: 'Programs',
    description: 'Option for learning type dropdown field',
  },
  'learningType.option.Boot Camps': {
    id: 'learningType.option.Boot Camps',
    defaultMessage: 'Boot camps',
    description: 'Option for learning type dropdown field',
  },
  'learningType.option.Degrees': {
    id: 'learningType.option.Degree Programs',
    defaultMessage: 'Degrees',
    description: 'Option for learning type dropdown field',
  },
  'learningType.option.Executive Education': {
    id: 'learningType.option.Executive Education',
    defaultMessage: 'Executive Education',
    description: 'Option for learning type dropdown field',
  },
  'learningType.option.unsure': {
    id: 'learningType.option.Unsure',
    defaultMessage: 'Unsure',
    description: 'Option for learning type dropdown field',
  },
  // ***** Learning Goal Field ****
  learningGoalFieldLabel: {
    id: 'learningGoal.field.label',
    defaultMessage: 'What goal is most important to you?',
    description: 'Label for the field that asks user about their learning goal',
  },
  learningGoalEmptyFieldError: {
    id: 'learningGoal.empty.field.error',
    defaultMessage: 'Please select your learning goal',
    description: 'Error for learningGoal field',
  },
  'learningGoal.option.change-career': {
    id: 'learningGoal.option.change-career',
    defaultMessage: 'I want to change careers',
    description: 'Option for the field that asks user about their learning goal',
  },
  'learningGoal.option.advance-career': {
    id: 'learningGoal.option.advance-career',
    defaultMessage: 'I want to advance my career',
    description: 'Option for the field that asks user about their learning goal',
  },
  'learningGoal.option.explore-new-skills': {
    id: 'learningGoal.option.explore-new-skills',
    defaultMessage: 'I want to learn something new',
    description: 'Option for the field that asks user about their learning goal',
  },
  'learningGoal.option.other': {
    id: 'learningGoal.option.other',
    defaultMessage: 'Other',
    description: 'Option for the field that asks user about their learning goal',
  },
  'learningGoal.option.unsure': {
    id: 'learningGoal.option.unsure',
    defaultMessage: 'I don’t know',
    description: 'Option for the field that asks user about their learning goal',
  },
  // ***** Level of Education Field *****
  levelOfEducationFieldLabel: {
    id: 'levelOfEducation.field.label',
    defaultMessage: 'What is your highest level of education completed? '
                    + 'This will help us show you offerings that you qualify for.',
    description: 'Label for education level field',
  },
  levelOfEducationEmptyFieldError: {
    id: 'levelOfEducation.empty.field.error',
    defaultMessage: 'Please select your education level',
    description: 'Error for levelOfEducation field',
  },
  'levelOfEducation.option.none': {
    id: 'levelOfEducation.option.none',
    defaultMessage: 'No formal education',
    description: 'Option for education level field',
  },
  'levelOfEducation.option.jhs': {
    id: 'levelOfEducation.option.jhs',
    defaultMessage: 'Junior secondary/junior high/middle school',
    description: 'Option for education level field',
  },
  'levelOfEducation.option.hs': {
    id: 'levelOfEducation.option.hs',
    defaultMessage: 'Secondary/High School',
    description: 'Option for education level field',
  },
  'levelOfEducation.option.a': {
    id: 'levelOfEducation.option.a',
    defaultMessage: 'Associate Degree',
    description: 'Option for education level field',
  },
  'levelOfEducation.option.b': {
    id: 'levelOfEducation.option.b',
    defaultMessage: 'Bachelor\'s Degree',
    description: 'Option for education level field',
  },
  'levelOfEducation.option.m': {
    id: 'levelOfEducation.option.m',
    defaultMessage: 'Master\'s or professional degree',
    description: 'Option for education level field',
  },
  'levelOfEducation.option.p': {
    id: 'levelOfEducation.option.p',
    defaultMessage: 'Doctorate',
    description: 'Option for education level field',
  },
  'levelOfEducation.option.other': {
    id: 'levelOfEducation.option.other',
    defaultMessage: 'Other',
    description: 'Option for education level field',
  },
  // ***** Work Experience Field *****
  workExperienceFieldLabel: {
    id: 'workExperience.field.label',
    defaultMessage: 'How many years of work experience do you have?',
    description: 'Label for work experience field',
  },
  workExperienceEmptyFieldError: {
    id: 'workExperience.empty.field.error',
    defaultMessage: 'Please select the number of years of work experience',
    description: 'Error for workExperience field',
  },
  'workExperience.option.0yrs': {
    id: 'workExperience.option.0yrs',
    defaultMessage: 'I don’t have any work experience',
    description: 'Option for work experience field',
  },
  'workExperience.option.1-5yrs': {
    id: 'workExperience.option.1-5yrs',
    defaultMessage: 'I have 1-5 years of work experience',
    description: 'Option for work experience field',
  },
  'workExperience.option.6-10yrs': {
    id: 'workExperience.option.6-10yrs',
    defaultMessage: 'I have 6-10 years of work experience',
    description: 'Option for work experience field',
  },
  'workExperience.option.11-15yrs': {
    id: 'workExperience.option.11-15yrs',
    defaultMessage: 'I have 11-15 years of work experience',
    description: 'Option for work experience field',
  },
  'workExperience.option.16-20yrs': {
    id: 'workExperience.option.16-20yrs',
    defaultMessage: 'I have 16-20 years of work experience',
    description: 'Option for work experience field',
  },
  'workExperience.option.20+yrs': {
    id: 'workExperience.option.20+yrs',
    defaultMessage: 'More than 20 years of work experience',
    description: 'Option for work experience field',
  },
  // Registration Fields
  userProfilePageTitle: {
    id: 'userProfile.page.title',
    defaultMessage: 'Almost done! Please share a few additional details with us.',
    description: 'Title on the page where we ask user to fill user profile related fields',
  },
  registrationPageTitle: {
    id: 'registration.page.title',
    defaultMessage: 'Lastly, please create an edX account to see your personalized recommendations.',
    description: 'Title on the page where we ask user to fill registration related fields',
  },
  nameFieldLabel: {
    id: 'name.field.label',
    defaultMessage: 'Tell us your full name',
    description: 'Label for full name field',
  },
  nameEmptyFieldError: {
    id: 'name.empty.field.error',
    defaultMessage: 'Enter your full name',
    description: 'Error message when no full name is added',
  },
  nameInvalidFieldError: {
    id: 'name.invalid.field.error',
    defaultMessage: 'Enter a valid name',
    description: 'Error message for invalid name',
  },
  countryFieldLabel: {
    id: 'country.field.label',
    defaultMessage: 'What country are you located in?',
    description: 'Label for country field',
  },
  genderFieldLabel: {
    id: 'genderFieldLabel',
    defaultMessage: 'What is your gender? {optionalLabel}',
    description: 'Label for gender field',
  },
  'gender.option.m': {
    id: 'gender.option.m',
    defaultMessage: 'Male',
    description: 'Option for gender field',
  },
  'gender.option.f': {
    id: 'gender.option.f',
    defaultMessage: 'Female',
    description: 'Option for gender field',
  },
  'gender.option.o': {
    id: 'gender.option.o',
    defaultMessage: 'Other/Prefer Not to Say',
    description: 'Option for gender field',
  },
  usernameFieldLabel: {
    id: 'username.field.label',
    defaultMessage: 'Username',
    description: 'Label for username field',
  },
  usernameEmptyFieldError: {
    id: 'username.empty.field.error',
    defaultMessage: 'Enter your username',
    description: 'Error message when no username is added',
  },
  marketingEmailsOptInLabel: {
    id: 'registration.opt.in.label',
    defaultMessage: 'I agree that edX may send me marketing messages',
    description: 'Label for checkbox asking users if they consent to marketing emails from edX',
  },
  // Other labels
  laborMarketDataLabel: {
    id: 'laborMarketDataLabel',
    defaultMessage: 'labor market data',
    description: 'Label of labor market data',
  },
  reachYourLearningAndProfessionalGoalsLabel: {
    id: 'reachYourLearningAndProfessionalGoalsLabel',
    defaultMessage: 'reach your learning and professional goals',
    description: 'Label of  learning and professional goals',
  },
  growChangeOrLearnLabel: {
    id: 'growChangeOrLearnLabel',
    defaultMessage: 'grow in your career, change careers, or just learn new skills',
    description: 'Label for grow in your career, change careers, or just learn new skills',
  },
  relevantCourseOrProgramLabel: {
    id: 'relevantCourseOrProgramLabel',
    defaultMessage: 'relevant course or program',
    description: 'Label for relevant course or program',
  },
  learnerGuideMessage: {
    id: 'learnerGuideMessage',
    defaultMessage: 'We combine the {educationExpertise} with {laborMarketData} to help you {reachYourLearningAndProfessionalGoals}. Whether you are looking to {growChangeOrLearn}, this tool can help you find a {relevantCourseOrProgram}.',
    description: 'Quiz Match value prop for learners',
  },
  optionalTextLabel: {
    id: 'optionalTextLabel',
    defaultMessage: 'optional',
    description: 'Label for optional text',
  },
  educationExpertiseLabel: {
    id: 'educationExpertiseLabel',
    defaultMessage: 'educational expertise',
    description: 'label for educational expertise',
  },
});

export default messages;
