import quizMessages from './messages';
import { HTML_REGEX, INVALID_NAME_REGEX, URL_REGEX } from '../../../data/constants';
import { validateCountry } from '../../../form-fields/country-field/data/utils';
import { validateEmail, validatePassword, validateUsername } from '../../../form-fields/utils';

const validateName = (value, formatMessage) => {
  let fieldError;
  if (!value.trim()) {
    fieldError = formatMessage(quizMessages.nameEmptyFieldError);
  } else if (URL_REGEX.test(value) || HTML_REGEX.test(value) || INVALID_NAME_REGEX.test(value)) {
    fieldError = formatMessage(quizMessages.nameInvalidFieldError);
  }
  return fieldError;
};

export default function validateField(formData, formErrors, fieldsToValidate, formatMessage, numberOfSuggestions) {
  let isValid = true;
  const validations = {};
  fieldsToValidate.forEach(fieldName => {
    if (fieldName === 'country') {
      const error = validateCountry(formData[fieldName], formatMessage);
      if (error) {
        validations.country = error;
        isValid = false;
      }
    } else if (fieldName === 'password') {
      const error = formErrors.password || validatePassword(formData[fieldName], formatMessage);
      if (error) {
        validations.password = error;
        isValid = false;
      }
    } else if (fieldName === 'username') {
      const error = formErrors.username || validateUsername(formData[fieldName], formatMessage, numberOfSuggestions);
      if (error) {
        validations.username = error;
        isValid = false;
      }
    } else if (fieldName === 'email') {
      const error = formErrors.email || validateEmail(formData[fieldName], formatMessage);
      if (error) {
        validations.email = error;
        isValid = false;
      }
    } else if (fieldName === 'name') {
      const error = formErrors.name || validateName(formData[fieldName], formatMessage);
      if (error) {
        validations.name = error;
        isValid = false;
      }
    } else if (!formData[fieldName]) {
      validations[fieldName] = formatMessage(quizMessages[`${fieldName}EmptyFieldError`]);
      isValid = false;
    } else {
      validations[fieldName] = '';
    }
  });

  return { isValid, validations };
}
