import { getConfig } from '@edx/frontend-platform';

import { QUIZ_1_VARIATION, QUIZ_2_VARIATION } from './constants';

const QUIZ_MATCH_ITERATION_1 = 'url_targeting_for_quiz_match_iteration1';

export function getQuizMatchIteration1ExperimentVariation() {
  try {
    if (window.optimizely && window.optimizely.get('data').experiments[getConfig().QUIZ_MATCH_EXPERIMENT_ID]) {
      const selectedVariant = window.optimizely.get('state').getVariationMap()[getConfig().QUIZ_MATCH_EXPERIMENT_ID];
      return selectedVariant?.name;
    }
  } catch (e) { /* empty */ }
  return '';
}

export function activateQuizMatchIteration1Experiment() {
  window.optimizely = window.optimizely || [];
  window.optimizely.push({
    type: 'page',
    pageName: QUIZ_MATCH_ITERATION_1,
  });
}

export const getRandomQuizMatchVariation = () => (Math.random() >= 0.5 ? QUIZ_1_VARIATION : QUIZ_2_VARIATION);
