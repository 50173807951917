import React from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import { Button, Icon, IconButton } from '@openedx/paragon';
import { Close } from '@openedx/paragon/icons';
import PropTypes, { string } from 'prop-types';

import './index.scss';
import messages from './messages';
import FormGroup from '../wrappers/FormGroup';

const UsernameField = (props) => {
  const { formatMessage } = useIntl();
  const {
    handleUsernameSuggestionClose, usernameSuggestions, errorMessage,
  } = props;
  let className = '';
  let suggestedUsernameDiv = null;
  let iconButton = null;

  const handleChange = (event) => {
    if (usernameSuggestions) {
      handleUsernameSuggestionClose();
    }
    props.onChange(event);
  };

  const handleFocus = (event) => {
    if (usernameSuggestions) {
      handleUsernameSuggestionClose();
    }
    props.onFocus(event);
  };

  const handleSuggestionClick = (event, suggestion = '') => {
    event.preventDefault();
    props.onChange({ target: { name: 'username', value: suggestion } });
    props.onFocus(event);
  };

  const suggestedUsernames = () => (
    <div className={className}>
      <span className="text-gray username-suggestion--label">{formatMessage(messages.usernameSuggestionLabel)}</span>
      <div className="username-scroll-suggested--form-field">
        {usernameSuggestions.map((username, index) => (
          <Button
            type="button"
            name="username-suggestion"
            variant="outline-dark"
            className="username-suggestions--chip data-hj-suppress"
            key={`suggestion-${index.toString()}`}
            onClick={(event) => handleSuggestionClick(event, username)}
          >
            {username}
          </Button>
        ))}
      </div>
      {iconButton}
    </div>
  );

  if (usernameSuggestions.length > 0 && errorMessage && !props.value) {
    className = 'username-suggestions__error ml-2.5';
    iconButton = <IconButton src={Close} iconAs={Icon} alt="Close" onClick={() => handleUsernameSuggestionClose()} variant="black" size="sm" className="username-suggestions__close__button" />;
    suggestedUsernameDiv = suggestedUsernames();
  } else if (usernameSuggestions.length > 0 && !props.value) {
    className = 'username-suggestions ml-2.5 d-flex align-items-center';
    iconButton = <IconButton src={Close} iconAs={Icon} alt="Close" onClick={() => handleUsernameSuggestionClose()} variant="black" size="sm" className="username-suggestions__close__button" />;
    suggestedUsernameDiv = suggestedUsernames();
  }

  return (
    <FormGroup
      {...props}
      onFocus={handleFocus}
      onChange={handleChange}
      autoComplete="username"
    >
      {suggestedUsernameDiv}
    </FormGroup>
  );
};

UsernameField.defaultProps = {
  errorMessage: '',
  className: '',
  usernameSuggestions: [],
};

UsernameField.propTypes = {
  usernameSuggestions: PropTypes.arrayOf(string),
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  // Handlers
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleUsernameSuggestionClose: PropTypes.func.isRequired,
};

export default UsernameField;
