import React from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import { Form, FormControlFeedback } from '@openedx/paragon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './index.scss';
import messages from './messages';

const SingleSelectDropdown = (props) => {
  const { formatMessage } = useIntl();
  const {
    label, options, name, placeholder, value, fieldMessages, errorMessage, readOnly,
  } = props;
  // prop readOnly=false can only be used if your Option's display values and keys are same.

  const handleClick = (selectedTarget) => {
    const selectedValue = selectedTarget.currentTarget.dataset?.key;
    const event = { target: { name, value: selectedValue } };

    props.onChange(event);
    if (props.onFocus) { props.onFocus(event); }
  };

  const handleFocus = (event) => {
    if (props.onFocus) { props.onFocus(event); }
  };

  const handleChange = (val) => {
    props.onChange({ target: { name, value: val } });
  };

  return (
    <Form.Group controlId={name}>
      <Form.Label className="h4 text-gray-700">{label}</Form.Label>
      <Form.Autosuggest
        name={name}
        value={(readOnly && value) ? formatMessage(fieldMessages[`${name}.option.${value}`]) : value}
        placeholder={placeholder || formatMessage(messages.defaultDropdownPlaceholder)}
        aria-invalid={!!errorMessage}
        autoComplete="off"
        readOnly={readOnly}
        className={classNames({ 'form-field-error': errorMessage })}
        // Event Handlers
        onFocus={handleFocus}
        onChange={handleChange}
      >
        {options.map((option) => (
          <Form.AutosuggestOption
            className="text-gray-700"
            data-key={option}
            key={option}
            onClick={handleClick}
          >
            {formatMessage(fieldMessages[`${name}.option.${option}`])}
          </Form.AutosuggestOption>
        ))}
      </Form.Autosuggest>

      {errorMessage && (
        <FormControlFeedback className="text-brand-500 mt-n2.5" feedback-for={name} type="invalid" hasIcon={false}>
          {errorMessage}
        </FormControlFeedback>
      )}
    </Form.Group>
  );
};

SingleSelectDropdown.propTypes = {
  errorMessage: PropTypes.string,
  fieldMessages: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  // Handlers
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
};

SingleSelectDropdown.defaultProps = {
  fieldMessages: {},
  placeholder: '',
  errorMessage: '',
  onFocus: null,
  readOnly: true,
};

export default SingleSelectDropdown;
