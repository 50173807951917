import { useEffect, useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import { STEP1 } from '../../../../data/constants';
import { stepsBackgroundColor, stepToImageMapping } from '../../data/constants';

const useHeroImageAndColor = (currentStep) => {
  const [image, setImage] = useState('');
  const color = currentStep !== STEP1 ? stepsBackgroundColor : '';

  // We are using non-standard breakpoints to avoid mismatch between rendered image sizes and actual image sizes
  const isLarge = useMediaQuery({ query: '(min-width: 992px)' });
  const isMedium = useMediaQuery({ query: '(min-width: 709px) and (max-width: 991px)' });
  const isSmall = useMediaQuery({ query: '(min-width: 465px) and (max-width: 708px)' });
  const isExtraSmall = useMediaQuery({ query: '(min-width: 375px) and (max-width: 464px)' });

  useEffect(() => {
    if (isLarge) {
      setImage(stepToImageMapping[currentStep].lg);
    } else if (isMedium) {
      setImage(stepToImageMapping[currentStep].md);
    } else if (isSmall) {
      setImage(stepToImageMapping[currentStep].sm);
    } else if (isExtraSmall) {
      setImage(stepToImageMapping[currentStep].xs);
    } else {
      setImage(stepToImageMapping[currentStep].xss);
    }
  }, [currentStep, isExtraSmall, isLarge, isMedium, isSmall]);

  return { image, color };
};

export default useHeroImageAndColor;
