import { COUNTRY_CODE_KEY } from './constants';
import { getUserLocation } from '../../../data/location';
import countryMessages from '../messages';

export const validateCountry = (country, formatMessage) => {
  let error = '';
  const { countryCode, displayValue } = country;
  if (!displayValue.trim()) {
    error = formatMessage(countryMessages['country.field.empty.error']);
  } else if (!countryCode) {
    error = formatMessage(countryMessages['invalid.country.field.error']);
  }

  return error;
};

export const getLocationBasedUserCountry = (countryList) => {
  const userLocation = getUserLocation();
  if (userLocation) {
    return countryList.find(
      (country) => (country[COUNTRY_CODE_KEY].toLowerCase() === userLocation.toLowerCase()),
    );
  }
  return '';
};
