import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  countryFieldLabel: {
    id: 'title.country.field',
    defaultMessage: 'What country are you located in?',
    description: 'Label for country field',
  },
  countryFieldPlaceholder: {
    id: 'country.field.placeholder',
    defaultMessage: 'Select an option',
    description: 'Default placeholder for the country field',
  },
  'country.field.empty.error': {
    id: 'country.field.empty.error',
    defaultMessage: 'Select your country or region of residence',
    description: 'Error message when no country/region is selected',
  },
  'invalid.country.field.error': {
    id: 'invalid.country.field.error',
    defaultMessage: 'Country must match with an option available in the dropdown.',
    description: 'Error message when country is invalid',
  },
});

export default messages;
