import React from 'react';

import { breakpoints } from '@openedx/paragon';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import BannerImageLayout from './BannerImageLayout';
import HeadingLayout from './HeadingLayout';

const LargeLayout = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.medium.minWidth + 1 });
  return isDesktop ? children : null;
};

LargeLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

const SmallLayout = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.small.maxWidth });
  return isMobile ? children : null;
};

SmallLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

const Header = () => (
  <>
    <LargeLayout>
      <BannerImageLayout />
    </LargeLayout>
    <SmallLayout>
      <HeadingLayout />
    </SmallLayout>
  </>
);

export default Header;
