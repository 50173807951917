import {
  breakpoints,
  useMediaQuery,
} from '@openedx/paragon';

import DesktopLoadingView from './DesktopLoadingView';
import MobileLoadingView from './MobileLoadingView';

const LoadingView = () => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.medium.maxWidth - 1 });
  return (isMobile ? <MobileLoadingView /> : <DesktopLoadingView />);
};

export default LoadingView;
