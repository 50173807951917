import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  emailFieldLabel: {
    id: 'title.email.field',
    defaultMessage: 'Email',
    description: 'Label for email field',
  },
  didYouMeanAlertText: {
    id: 'did.you.mean.alert.text',
    defaultMessage: 'Did you mean',
    description: 'Did you mean alert suggestion',
  },
  emailHelpText: {
    id: 'help.text.email',
    defaultMessage: 'For account activation and important updates',
    description: 'Help text for email field on registration page',
  },
  emailEmptyFieldError: {
    id: 'empty.email.field.error',
    defaultMessage: 'Enter your email',
    description: 'Error message for empty email field',
  },
  emailInvalidFormatError: {
    id: 'email.invalid.format.error',
    defaultMessage: 'Enter a valid email address',
    description: 'Validation error for invalid email address',
  },
});

export default messages;
