import imageExtraExtraSmall from './edX_match-quiz_V11_375x200.jpg';
import imageExtraSmall from './edX_match-quiz_V11_465x200.jpg';
import imageSmall from './edX_match-quiz_V11_708x240.jpg';
import imageLarge from './edX_match-quiz_V11_720x768.jpg';
import imageMedium from './edX_match-quiz_V11_952x240.jpg';

export default {
  imageLarge,
  imageMedium,
  imageSmall,
  imageExtraSmall,
  imageExtraExtraSmall,
};
