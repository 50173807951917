import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  accountCreationHeading1: {
    id: 'account.creation.heading.1',
    defaultMessage: 'Creating your ',
    description: 'Part of the loading screen heading. Complete heading is "Creating your account".',
  },
  accountCreationHeading2: {
    id: 'account.creation.heading.2',
    defaultMessage: 'account',
    description: 'Part of the loading screen heading. Complete heading is "Creating your account".',
  },
});

export default messages;
