import React from 'react';

import { Form, FormControlFeedback } from '@openedx/paragon';
import PropTypes from 'prop-types';

const FormGroup = (props) => {
  const handleFocus = (event) => {
    if (props.onFocus) { props.onFocus(event); }
  };
  const handleClick = (event) => {
    if (props.onClick) { props.onClick(event); }
  };
  const handleBlur = (event) => {
    if (props.onBlur) { props.onBlur(event); }
  };
  const handleChange = (event) => {
    if (props.onChange) {
      if (event.target.type === 'checkbox') {
        props.onChange({ target: { name: event.target.name, value: event.target.checked } });
      } else {
        props.onChange(event);
      }
    }
  };

  if (props.type === 'checkbox') {
    return (
      <Form.Group className="mb-2.5" controlId={props.name} isInvalid={props.errorMessage !== ''}>
        <Form.Checkbox
          className="micro"
          id={props.id}
          name={props.name}
          checked={!!props.value}
          // Event Handlers
          onChange={handleChange}
        >
          <span className="checkbox-label">{props.label}</span>
        </Form.Checkbox>
      </Form.Group>
    );
  }

  return (
    <Form.Group className={props.className} controlId={props.name} isInvalid={props.errorMessage !== ''}>
      <Form.Label className="h4 text-gray-700">{props.label}</Form.Label>
      <Form.Control
        name={props.name}
        value={props.value}
        type={props.type}
        aria-invalid={!!props.errorMessage}
        className="form-group__form-field"
        controlClassName={props.borderClass}
        autoComplete={props.autoComplete}
        // Event Handlers
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onChange={handleChange}
      />
      {props.errorMessage !== '' && (
        <FormControlFeedback className="text-brand-500" feedback-for={props.name} type="invalid" hasIcon={false}>
          {props.errorMessage}
        </FormControlFeedback>
      )}
      {props.children}
    </Form.Group>
  );
};

FormGroup.propTypes = {
  autoComplete: PropTypes.string,
  borderClass: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
  errorMessage: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  // Handlers
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

FormGroup.defaultProps = {
  autoComplete: 'on',
  borderClass: '',
  className: '',
  children: null,
  errorMessage: '',
  id: '',
  label: '',
  name: '',
  type: 'text',
  value: '',
  // Handlers
  onBlur: null,
  onClick: null,
  onFocus: null,
};

export default FormGroup;
