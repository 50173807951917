import React, { useState } from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import {
  Form, FormControlFeedback, Icon, IconButton, OverlayTrigger, Tooltip, useToggle,
} from '@openedx/paragon';
import {
  Check, Remove, Visibility, VisibilityOff,
} from '@openedx/paragon/icons';
import PropTypes from 'prop-types';

import { LETTER_REGEX, NUMBER_REGEX } from './data/constants';
import messages from './messages';

const PasswordField = ({
  value, errorMessage, handleChange, handleFocus, className,
}) => {
  const { formatMessage } = useIntl();
  const [isPasswordHidden, setHiddenTrue, setHiddenFalse] = useToggle(true);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOnBlur = () => {
    setShowTooltip(false);
  };

  const handleOnChange = (e) => {
    handleChange(e);
  };

  const handleOnFocus = (e) => {
    setTimeout(() => setShowTooltip(true), 150);
    handleFocus(e);
  };

  const HideButton = (
    <IconButton onFocus={handleOnFocus} onBlur={handleOnBlur} name="password" src={VisibilityOff} iconAs={Icon} onClick={setHiddenTrue} size="sm" variant="secondary" alt={formatMessage(messages.hidePassword)} />
  );

  const ShowButton = (
    <IconButton onFocus={handleOnFocus} onBlur={handleOnBlur} name="password" src={Visibility} iconAs={Icon} onClick={setHiddenFalse} size="sm" variant="secondary" alt={formatMessage(messages.showPassword)} />
  );
  const placement = window.innerWidth < 1200 ? 'top' : 'left';
  const tooltip = (
    <Tooltip id={`password-requirement-${placement}`}>
      <span id="letter-check" className="d-flex align-items-center">
        {LETTER_REGEX.test(value) ? <Icon className="text-success mr-1" src={Check} /> : <Icon className="mr-1 text-light-700" src={Remove} />}
        {formatMessage(messages.oneLetter)}
      </span>
      <span id="number-check" className="d-flex align-items-center">
        {NUMBER_REGEX.test(value) ? <Icon className="text-success mr-1" src={Check} /> : <Icon className="mr-1 text-light-700" src={Remove} />}
        {formatMessage(messages.oneNumber)}
      </span>
      <span id="characters-check" className="d-flex align-items-center">
        {value.length >= 8 ? <Icon className="text-success mr-1" src={Check} /> : <Icon className="mr-1 text-light-700" src={Remove} />}
        {formatMessage(messages.eightCharacters)}
      </span>
    </Tooltip>
  );

  return (
    <Form.Group className={className} controlId="password" isInvalid={errorMessage !== ''}>
      <Form.Label className="h4 text-gray-700">{formatMessage(messages.passwordFieldLabel)}</Form.Label>
      <OverlayTrigger key="tooltip" placement={placement} overlay={tooltip} show={showTooltip}>
        <Form.Control
          as="input"
          className="form-group__form-field"
          type={isPasswordHidden ? 'password' : 'text'}
          label={formatMessage(messages.passwordFieldLabel)}
          name="password"
          autoComplete="password"
          value={value}
          aria-invalid={errorMessage !== ''}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          trailingElement={isPasswordHidden ? ShowButton : HideButton}
        />
      </OverlayTrigger>
      {errorMessage !== '' && (
        <FormControlFeedback className="text-brand-500" hasIcon={false} feedback-for="password" type="invalid">
          {errorMessage}
          <span className="sr-only">{formatMessage(messages.passwordSROnlyHelpingText)}</span>
        </FormControlFeedback>
      )}
    </Form.Group>
  );
};

PasswordField.defaultProps = {
  errorMessage: '',
  className: '',
};

PasswordField.propTypes = {
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func.isRequired,
};

export default PasswordField;
