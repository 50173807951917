import { getAuthenticatedHttpClient, getHttpClient } from '@edx/frontend-platform/auth';
import * as QueryString from 'query-string';

import {
  DEFAULT_REDIRECT_URL,
  GET_USERNAME_SUGGESTIONS_ENDPOINT,
  REGISTER_ENDPOINT,
} from './constants';

async function registerRequest(registrationInformation) {
  const requestConfig = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    isPublic: true,
  };

  const { data } = await getAuthenticatedHttpClient()
    .post(REGISTER_ENDPOINT, QueryString.stringify(registrationInformation), requestConfig)
    .catch((e) => {
      throw (e);
    });

  return {
    redirectUrl: data.redirect_url || DEFAULT_REDIRECT_URL,
    success: data.success || false,
  };
}

async function getUsernameSuggestions(formPayload) {
  const requestConfig = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };

  const { data } = await getHttpClient()
    .post(
      GET_USERNAME_SUGGESTIONS_ENDPOINT,
      QueryString.stringify(formPayload),
      requestConfig,
    )
    .catch((e) => {
      throw (e);
    });

  return {
    fieldValidations: data,
  };
}

export { registerRequest, getUsernameSuggestions };
