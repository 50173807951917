import React, { useEffect, useRef } from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import { breakpoints } from '@openedx/paragon';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

import './index.scss';
import useHeroImageAndColor from './hooks';
import LeftBannerLayout from './LeftBannerLayout';
import TopBannerLayout from './TopBannerLayout';
import loaderImage from '../../../../assets/loader/accountCreationLoaderAsset.svg';
import { STEP1, STEP7 } from '../../../../data/constants';
import { LogoOnlyHeader } from '../../../../header';
import { stepToImageMapping } from '../../data/constants';
import messages from '../../data/messages';
import { cacheImages } from '../../data/quiz-utils';

const LargeLayout = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.large.minWidth });
  return isDesktop ? children : null;
};

LargeLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

const SmallLayout = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.medium.maxWidth - 1 });
  return isMobile ? children : null;
};

SmallLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

const BaseContainer = ({ children, currentStep, backStepHandler }) => {
  const { formatMessage } = useIntl();
  const formRef = useRef(null);

  const { image, color } = useHeroImageAndColor(currentStep);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentStep]);

  useEffect(() => {
    cacheImages({
      accountCreationLoader: loaderImage,
    });
  }, []);

  useEffect(() => {
    if (currentStep < STEP7) {
      cacheImages(stepToImageMapping[currentStep + 1]);
    }
  }, [currentStep]);

  const stepsImageText = () => {
    if (currentStep === STEP1) {
      return formatMessage(messages.firstStepImageText);
    } if (currentStep === STEP7) {
      return formatMessage(messages.lastStepImageText);
    }
    return formatMessage(messages.stepImageAltText);
  };

  return (
    <div ref={formRef} className="d-flex flex-column vh-100">
      <LogoOnlyHeader />
      <main id="main-content" name="main-content" className="d-flex flex-grow-1 justify-content-between layout-container" aria-label="Content">
        <LargeLayout>
          <LeftBannerLayout
            leftBannerColor={color}
            leftBannerImage={image}
            backStepHandler={currentStep !== STEP1 ? backStepHandler : null}
            currentStep={currentStep}
            stepsImageText={stepsImageText()}
          />
        </LargeLayout>
        <SmallLayout>
          <TopBannerLayout
            topBannerImage={image}
            backStepHandler={currentStep !== STEP1 ? backStepHandler : null}
            stepsImageText={stepsImageText()}
          />
        </SmallLayout>
        <div className="d-flex flex-grow-1 w-100 w-lg-50">
          {children}
        </div>
      </main>
    </div>
  );
};

BaseContainer.propTypes = {
  children: PropTypes.node.isRequired,
  currentStep: PropTypes.number.isRequired,
  backStepHandler: PropTypes.func.isRequired,
};

export default BaseContainer;
