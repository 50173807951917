import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';

import {
  APP_INIT_ERROR, APP_READY, initialize, mergeConfig, subscribe,
} from '@edx/frontend-platform';
import { AppProvider, ErrorPage, PageWrap } from '@edx/frontend-platform/react';
import { Navigate, Route, Routes } from 'react-router-dom';

import NotFoundPage from './common-components/NotFoundPage';
import QuizContainer from './containers/quiz-container-1/index';
import QuizContainer2 from './containers/quiz-container-2/index';
import { MATCH_QUIZ_ROUTE, MATCH_QUIZ_ROUTE_V2, PAGE_NOT_FOUND } from './data/constants';
import updatePathWithQueryParams from './data/utils';
import messages from './i18n';
import './index.scss';

subscribe(APP_READY, () => {
  const stageEnv = process.env.ENVIRONMENT_PREFIX === 'stage';
  ReactDOM.render(
    <AppProvider>
      <Routes>
        <Route path="/" element={<Navigate to={updatePathWithQueryParams(MATCH_QUIZ_ROUTE)} replace />} />
        <Route path={MATCH_QUIZ_ROUTE} element={<PageWrap><QuizContainer /></PageWrap>} />
        {stageEnv && <Route path="/match-quiz-2" element={<PageWrap><QuizContainer2 /></PageWrap>} />}
        <Route path={MATCH_QUIZ_ROUTE_V2} element={<PageWrap><QuizContainer2 /></PageWrap>} />
        <Route path={PAGE_NOT_FOUND} element={<PageWrap><NotFoundPage /></PageWrap>} />
        <Route path="*" element={<PageWrap><Navigate to={PAGE_NOT_FOUND} replace /></PageWrap>} />
      </Routes>
    </AppProvider>,
    document.getElementById('root'),
  );
});

subscribe(APP_INIT_ERROR, (error) => {
  ReactDOM.render(<ErrorPage message={error.message} />, document.getElementById('root'));
});

initialize({
  messages,
  handlers: {
    config: () => {
      mergeConfig({
        SEARCH_CATALOG_URL: process.env.SEARCH_CATALOG_URL || '',
        SITE_NAME: process.env.SITE_NAME || '',
        TERMS_OF_SERVICE_URL: process.env.TERMS_OF_SERVICE_URL || '',
        PRIVACY_POLICY_URL: process.env.PRIVACY_POLICY_URL || '',
        LOGO_URL: process.env.LOGO_URL || '',
        ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID || '',
        ALGOLIA_SEARCH_API_KEY: process.env.ALGOLIA_SEARCH_API_KEY || '',
        ENVIRONMENT_PREFIX: process.env.ENVIRONMENT_PREFIX || '',
        QUIZ_MATCH_EXPERIMENT_ID: process.env.QUIZ_MATCH_EXPERIMENT_ID || '',
      }, 'App loadConfig override handler');
    },
  },
});
