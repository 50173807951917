import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  passwordFieldLabel: {
    id: 'title.password.field',
    defaultMessage: 'Password',
    description: 'Label for password field',
  },
  // password field strings
  showPassword: {
    id: 'show.password',
    defaultMessage: 'Show password',
    description: 'aria label for show password icon on password field',
  },
  hidePassword: {
    id: 'hide.password',
    defaultMessage: 'Hide password',
    description: 'aria label for hide password icon on password field',
  },
  oneLetter: {
    id: 'one.letter',
    defaultMessage: '1 letter',
    description: 'password requirement to have 1 letter',
  },
  oneNumber: {
    id: 'one.number',
    defaultMessage: '1 number',
    description: 'password requirement to have 1 number',
  },
  eightCharacters: {
    id: 'eight.characters',
    defaultMessage: '8 characters',
    description: 'password requirement to have a minimum of 8 characters',
  },
  passwordSROnlyHelpingText: {
    id: 'password.sr.only.helping.text',
    defaultMessage: 'Password must contain at least 8 characters, at least one letter, and at least one number',
    description: 'Password helping text for the sr-only class',
  },
  // validation errors
  passwordValidationMessage: {
    id: 'password.validation.message',
    defaultMessage: 'Password criteria has not been met',
    description: 'Error message for empty or invalid password',
  },
});

export default messages;
