import { getConfig } from '@edx/frontend-platform';
import algoliasearch from 'algoliasearch';

// initialize Algolia workers
const initializeSearchClient = () => algoliasearch(
  getConfig().ALGOLIA_APP_ID,
  getConfig().ALGOLIA_SEARCH_API_KEY,
);

export default initializeSearchClient;
