import step1Images from './s1';
import step2Images from './s2';
import step3Images from './s3';
import step4Images from './s4';
import step5Images from './s5';
import step6Images from './s6';
import step7Images from './s7';

export default {
  step1Images,
  step2Images,
  step3Images,
  step4Images,
  step5Images,
  step6Images,
  step7Images,
};
