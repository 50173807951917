import {
  Skeleton,
} from '@openedx/paragon';
import '../index.scss';

const MobileLoadingView = () => (
  <div className="d-flex flex-column vh-100">
    <div className="top-skeleton bg-light-300">
      <div className="px-md-3 px-sm-4 px-4 py-md-4.5 py-sm-3.5 py-4">
        <Skeleton className="bg-light-400 pb-1" count={3} height={16} />
      </div>
    </div>
    <div className="pt-3 px-4 flex-grow-1 overflow-hidden">
      <div>
        <Skeleton className="bg-light-400 pb-1 skeleton-max-width-193" height={8} />
        <Skeleton className="bg-light-400 pb-1" count={2} height={20} />
      </div>
      <div className="pt-3 d-flex mobile-skeleton-options-container">
        <div className="w-50">
          <Skeleton className="skeleton-options" height={110} count={5} />
        </div>
        <div className="w-50">
          <Skeleton className="skeleton-options" height={110} count={5} />
        </div>
      </div>
    </div>
  </div>
);

export default MobileLoadingView;
