import React, { useEffect, useRef, useState } from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import {
  Container, Form, Sheet, Spinner, Stepper,
} from '@openedx/paragon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useMediaQuery } from 'react-responsive';

import messages from './messages';
import {
  BACKEND_ERRORS_KEYS,
  CURRENT_ITERATION,
  DEFAULT_REDIRECT_URL,
  LEARNING_TYPES_FOR_PARAMS,
  QUIZ1_FIELD_MAPPING,
  QUIZ_1_VARIATION,
  STEP1,
  STEP2,
  STEP3,
  STEP4,
  STEP5,
  STEP6,
  STEP7,
} from '../../../../data/constants';
import { getUsernameSuggestions, registerRequest } from '../../../../data/service';
import {
  trackPiiQuestionsViewedEvent,
  trackQuizMatchSubmitButtonClicked,
  trackQuizQuestionViewedEvent,
  trackQuizSubmittedEvent,
} from '../../../../data/track';
import {
  CountryField, EmailField, FormGroup, PasswordField, SingleSelectDropdown, TermsOfServiceAndHonorCode, UsernameField,
} from '../../../../form-fields';
import { Header } from '../../../../header';
import { learningTypeFieldMapping } from '../../data/field-mappings';
import { NUM_OF_STEPS, questions } from '../../data/quiz-v1-question-description';
import {
  convertObjectToQueryParams,
  getRegionBasedLearningTypes,
  validateSubjectOnChange,
} from '../../data/utils/quiz-utils';
import validateField from '../../data/utils/quiz-validator';
import StepperFooter from '../stepper-footer';

const QuizV1 = ({
  experiment, isLoading, isOrganicTraffic, quizValueProp, subjectsList,
}) => {
  const { formatMessage } = useIntl();

  const formRef = useRef(null);

  const isFullScreenView = useMediaQuery({ maxWidth: 952 });
  const registrationMutation = useMutation(registerRequest);
  const usernameSuggestionMutation = useMutation(getUsernameSuggestions);
  const eventProps = {
    variation: QUIZ_1_VARIATION,
    iteration: CURRENT_ITERATION,
    organic_traffic: isOrganicTraffic,
    experiment,
  };

  const [currentStep, setCurrentStep] = useState(STEP1);
  const [usernameSuggestions, setUsernameSuggestions] = useState([]);
  const [quizFormData, setQuizFormData] = useState({
    subject: '',
    skillLevel: '',
    learningType: '',
    levelOfEducation: '',
    workExperience: '',
    name: '',
    country: { countryCode: '', displayValue: '' },
    gender: '',
    email: '',
    username: '',
    password: '',
    marketingEmailsOptIn: true,
  });
  const [quizFormErrors, setQuizFormErrors] = useState({
    learningType: '',
    skillLevel: '',
    subject: '',
    workExperience: '',
    name: '',
    email: '',
    username: '',
    password: '',
    country: '',
    levelOfEducation: '',
  });

  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentStep, registrationMutation.isError]);

  useEffect(() => {
    if (usernameSuggestionMutation.isSuccess) {
      setUsernameSuggestions(usernameSuggestionMutation.data?.fieldValidations.username_suggestions || []);
    }
  }, [usernameSuggestionMutation.isSuccess, usernameSuggestionMutation.data]);

  useEffect(() => {
    if (registrationMutation.isError && registrationMutation.error?.customAttributes?.httpErrorResponseData) {
      const errorResponseData = registrationMutation.error?.customAttributes?.httpErrorResponseData;
      const backendFieldValidationErrors = JSON.parse(errorResponseData);
      const validations = {};
      Object.keys(backendFieldValidationErrors).forEach((key) => {
        if (BACKEND_ERRORS_KEYS.includes(key)) {
          validations[key] = backendFieldValidationErrors[key][0].user_message;
        }
      });
      setQuizFormErrors(prevState => ({ ...prevState, ...validations }));
    }
  }, [registrationMutation.isError, registrationMutation.error]);

  useEffect(() => {
    const stepQuestion = QUIZ1_FIELD_MAPPING[`STEP${currentStep}`][0];
    const currentQuestion = questions[stepQuestion];
    if (currentStep <= STEP5) {
      const eventProperties = {
        ...eventProps,
        position: currentStep,
        label: currentQuestion.label,
        text: currentQuestion.text,
        type: currentQuestion.type,
      };
      trackQuizQuestionViewedEvent(eventProperties);
    } else {
      const eventProperties = {
        ...eventProps,
        position: currentStep,
      };
      trackPiiQuestionsViewedEvent(eventProperties);
    }
  }, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  if (registrationMutation.isSuccess) {
    const params = { subject: quizFormData.subject };
    if (quizFormData.learningType !== 'unsure') {
      const mappedLearningTypes = learningTypeFieldMapping.get(quizFormData.learningType);
      const learningTypeParams = [];
      const programTypeParams = [];
      mappedLearningTypes.forEach((type) => {
        if (LEARNING_TYPES_FOR_PARAMS.includes(type)) {
          learningTypeParams.push(type);
        } else {
          programTypeParams.push(type);
        }
      });
      if (learningTypeParams.length > 0) {
        params['learning-type'] = learningTypeParams;
      }
      if (programTypeParams.length > 0) {
        params.program_type = programTypeParams;
      }
    }

    const searchParams = convertObjectToQueryParams(params);
    const quizMatchSearchParams = `?quiz_match=true&variation=${QUIZ_1_VARIATION}&${searchParams}`;
    const paramsWithPrefix = Array.from(searchParams).length ? quizMatchSearchParams : '';

    trackQuizSubmittedEvent(
      { ...quizFormData, ...eventProps }, `${DEFAULT_REDIRECT_URL}/search${paramsWithPrefix}`,
    );
  }

  const handleSubmit = () => {
    trackQuizMatchSubmitButtonClicked({
      iteration: CURRENT_ITERATION,
      organic_traffic: isOrganicTraffic,
    });
    const { isValid, validations } = validateField(
      quizFormData, quizFormErrors, QUIZ1_FIELD_MAPPING.STEP7, formatMessage,
    );

    if (!isValid) {
      setQuizFormErrors(prevState => ({ ...prevState, ...validations }));
    } else {
      const payload = {
        country: quizFormData.country?.countryCode,
        email: quizFormData.email,
        honor_code: true,
        marketing_emails_opt_in: quizFormData.marketingEmailsOptIn,
        name: quizFormData.name,
        password: quizFormData.password,
        username: quizFormData.username,
        // optional fields
        gender: quizFormData.gender,
        level_of_education: quizFormData.levelOfEducation,
      };
      registrationMutation.mutate(payload);
    }
  };

  const handleNext = (stepNumber) => {
    const { isValid, validations } = validateField(
      quizFormData, quizFormErrors, QUIZ1_FIELD_MAPPING[`STEP${stepNumber}`], formatMessage, subjectsList,
    );

    if (!isValid) {
      setQuizFormErrors(prevState => ({ ...prevState, ...validations }));
      return;
    }
    setCurrentStep(stepNumber + 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === questions.subject.name) {
      const error = validateSubjectOnChange(value, subjectsList, formatMessage);
      setQuizFormErrors(prevState => ({ ...prevState, [name]: error }));
    }
    setQuizFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFocus = (event) => {
    const { name } = event.target;
    setQuizFormErrors(prevState => ({ ...prevState, [name]: '' }));
  };

  const generateUsernameSuggestions = (event) => {
    const { name, value } = event.target;
    if (!quizFormData.username && value) {
      const payload = {
        form_field_key: name,
        name: value,
      };
      usernameSuggestionMutation.mutate(payload);
    }
  };

  const handleUsernameSuggestionClosed = () => {
    setUsernameSuggestions([]);
  };

  return (
    <Stepper activeKey={`step${currentStep}`}>
      <div className="vh-100 d-flex flex-column">
        <div>
          <Header />
          <Stepper.Header />
        </div>
        {isLoading ? <Spinner animation="border" className="spinner" /> : (
          <>
            <div className="flex-grow-1 quiz-content">
              <Container
                ref={formRef}
                size="md"
                className={classNames(
                  { 'py-4.5 px-4': isFullScreenView },
                  { 'p-5': !isFullScreenView },
                )}
              >
                <Form>
                  <Stepper.Step title="" eventKey={`step${STEP1}`}>
                    <div className="mb-5 text-gray-700">{quizValueProp}</div>
                    <SingleSelectDropdown
                      fieldMessages={messages}
                      name={questions.subject.name}
                      value={quizFormData.subject}
                      label={formatMessage(messages.subjectFieldLabel)}
                      placeholder={formatMessage(messages.subjectFieldPlaceholder)}
                      errorMessage={quizFormErrors.subject}
                      options={subjectsList}
                      readOnly={false}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </Stepper.Step>

                  <Stepper.Step title="" eventKey={`step${STEP2}`}>
                    <SingleSelectDropdown
                      fieldMessages={messages}
                      name={questions.skillLevel.name}
                      value={quizFormData.skillLevel}
                      label={formatMessage(messages.skillLevelFieldLabel)}
                      placeholder={formatMessage(messages.skillLevelFieldPlaceholder)}
                      errorMessage={quizFormErrors.skillLevel}
                      options={questions.skillLevel.options}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </Stepper.Step>

                  <Stepper.Step title="" eventKey={`step${STEP3}`}>
                    <SingleSelectDropdown
                      fieldMessages={messages}
                      name={questions.learningType.name}
                      value={quizFormData.learningType}
                      label={formatMessage(messages.learningTypeFieldLabel)}
                      placeholder={formatMessage(messages.learningTypeFieldPlaceholder)}
                      errorMessage={quizFormErrors.learningType}
                      options={getRegionBasedLearningTypes(questions.learningType.options)}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </Stepper.Step>

                  <Stepper.Step title="" eventKey={`step${STEP4}`}>
                    <SingleSelectDropdown
                      fieldMessages={messages}
                      name={questions.levelOfEducation.name}
                      value={quizFormData.levelOfEducation}
                      label={formatMessage(messages.levelOfEducationFieldLabel)}
                      errorMessage={quizFormErrors.levelOfEducation}
                      options={questions.levelOfEducation.options}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </Stepper.Step>

                  <Stepper.Step title="" eventKey={`step${STEP5}`}>
                    <SingleSelectDropdown
                      fieldMessages={messages}
                      name={questions.workExperience.name}
                      value={quizFormData.workExperience}
                      label={formatMessage(messages.workExperienceFieldLabel)}
                      errorMessage={quizFormErrors.workExperience}
                      options={questions.workExperience.options}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </Stepper.Step>

                  <Stepper.Step title="" eventKey={`step${STEP6}`}>
                    <h4 className="text-gray-700 mb-3">{formatMessage(messages.userProfilePageTitle)}</h4>
                    <FormGroup
                      name="name"
                      value={quizFormData.name}
                      label={formatMessage(messages.nameFieldLabel)}
                      errorMessage={quizFormErrors.name}
                      className="mb-5"
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={generateUsernameSuggestions}
                    />
                    <CountryField
                      country={quizFormData.country}
                      errorMessage={quizFormErrors.country}
                      className="mb-5"
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                    <SingleSelectDropdown
                      fieldMessages={messages}
                      name={questions.gender.name}
                      value={quizFormData.gender}
                      label={
                        formatMessage(
                          messages.genderFieldLabel,
                          { optionalLabel: <em className="font-weight-normal">{formatMessage(messages.optionalTextLabel)}</em> },
                        )
                      }
                      errorMessage={quizFormErrors.gender}
                      options={questions.gender.options}
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                  </Stepper.Step>

                  <Stepper.Step title="" eventKey={`step${STEP7}`}>
                    <h4 className="text-gray-700 mb-3">{formatMessage(messages.registrationPageTitle)}</h4>
                    <Sheet
                      position="bottom"
                      show={registrationMutation.isLoading || registrationMutation.isSuccess}
                      blocking
                    />
                    <EmailField
                      value={quizFormData.email}
                      errorMessage={quizFormErrors.email}
                      className="mb-5"
                      onChange={handleChange}
                      onFocus={handleFocus}
                    />
                    <UsernameField
                      name="username"
                      value={quizFormData.username}
                      label={formatMessage(messages.usernameFieldLabel)}
                      errorMessage={quizFormErrors.username}
                      className="mb-5"
                      usernameSuggestions={usernameSuggestions}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      handleUsernameSuggestionClose={handleUsernameSuggestionClosed}
                    />
                    <PasswordField
                      value={quizFormData.password}
                      errorMessage={quizFormErrors.password}
                      className="mb-5"
                      handleChange={handleChange}
                      handleFocus={handleFocus}
                    />
                    <FormGroup
                      name="marketingEmailsOptIn"
                      value={quizFormData.marketingEmailsOptIn}
                      label={formatMessage(messages.marketingEmailsOptInLabel)}
                      type="checkbox"
                      className="mb-5"
                      onChange={handleChange}
                    />
                    <TermsOfServiceAndHonorCode />
                  </Stepper.Step>
                </Form>
              </Container>
            </div>
            <StepperFooter
              currentStep={currentStep}
              disabled={registrationMutation.isLoading || registrationMutation.isSuccess}
              numberOfSteps={NUM_OF_STEPS}
              setCurrentStep={setCurrentStep}
              onSubmit={handleSubmit}
              onNext={handleNext}
            />
          </>
        )}
      </div>
    </Stepper>
  );
};

QuizV1.propTypes = {
  experiment: PropTypes.string,
  isLoading: PropTypes.bool,
  isOrganicTraffic: PropTypes.bool.isRequired,
  quizValueProp: PropTypes.element.isRequired,
  subjectsList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

QuizV1.defaultProps = {
  experiment: '',
  isLoading: true,
};

export default QuizV1;
