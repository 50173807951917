import { snakeCaseObject } from '@edx/frontend-platform';

const LINK_TIMEOUT = 300;

const trackQuizQuestionViewedEvent = (eventProperties) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.track('edx.bi.user.quiz_match.question.viewed', eventProperties);
};

const trackPiiQuestionsViewedEvent = (eventProperties) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.track('edx.bi.user.quiz_match.pii.viewed', eventProperties);
};

const trackQuizSubmittedEvent = (eventProperties, redirectUrl) => {
  if (window.analytics) {
    let properties = {
      ...eventProperties,
      country: eventProperties.country.countryCode,
    };
    delete properties.password;
    properties = snakeCaseObject(properties);
    window.analytics.track('edx.bi.user.quiz_match.quiz.submitted', properties);
  }
  // Wait for the segment event to fire successfully before redirecting
  return setTimeout(() => { window.location.href = redirectUrl; }, LINK_TIMEOUT);
};

const trackQuizMatchSubmitButtonClicked = (eventProperties) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.track('edx.bi.user.quiz_match.submit.clicked', eventProperties);
};

const trackQuizMatchPreviousButtonClicked = (eventProperties) => {
  if (!window.analytics) {
    return;
  }

  window.analytics.track('edx.bi.user.quiz_match.previous_btn.clicked', eventProperties);
};

export {
  trackPiiQuestionsViewedEvent,
  trackQuizSubmittedEvent,
  trackQuizQuestionViewedEvent,
  trackQuizMatchSubmitButtonClicked,
  trackQuizMatchPreviousButtonClicked,
};
