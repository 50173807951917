/**
 * These are the product mappings we will use for match quiz recommendations
 */

const allLearningTypes = ['Course', 'Executive Education', 'Boot Camp', 'Professional Certificate', 'MicroBachelors',
  'MicroMasters', 'XSeries', 'Masters', 'Bachelors', 'Certificate', 'License', 'Doctorate'];

const allDegreesExceptBachelors = ['Masters', 'Certificate', 'License', 'Doctorate'];

// Learning type mappings
const matchQuizProductsMapping = new Map([
  ['Courses', ['Course', 'Executive Education', 'Professional Certificate', 'MicroBachelors', 'MicroMasters', 'XSeries']],
  ['Boot Camps', ['Boot Camp', 'Masters', 'Bachelors', 'Certificate', 'License', 'Doctorate']],
  ['Degrees', ['Masters', 'Bachelors', 'Certificate', 'License', 'Doctorate']],
  ['Executive Education', ['Executive Education']],
  ['unsure', allLearningTypes],
]);

/**
 * We want to keep all degrees if the level of education is in bachelors (b), masters or professional (m),
 * doctorate (p) or others (other)
 * And remove bachelors degree if level of education is in no formal education (none),
 * Junior secondary/junior high/middle school (jhs), Secondary/High School (hs) or Associate Degree (a).
 */
const levelOfEducationWithOnlyBachelorsDegree = ['none', 'jhs', 'hs', 'a'];

export {
  allLearningTypes,
  allDegreesExceptBachelors,
  matchQuizProductsMapping,
  levelOfEducationWithOnlyBachelorsDegree,
};
