import heroImages from '../../../assets/banner-images';

export const stepsBackgroundColor = '#EBA7BC';

export const stepToImageMapping = {
  1: {
    lg: heroImages.step1Images.imageLarge,
    md: heroImages.step1Images.imageMedium,
    sm: heroImages.step1Images.imageSmall,
    xs: heroImages.step1Images.imageExtraSmall,
    xss: heroImages.step1Images.imageExtraExtraSmall,
  },
  2: {
    lg: heroImages.step2Images.imageLarge,
    md: heroImages.step2Images.imageMedium,
    sm: heroImages.step2Images.imageSmall,
    xs: heroImages.step2Images.imageExtraSmall,
    xss: heroImages.step2Images.imageExtraExtraSmall,
  },
  3: {
    lg: heroImages.step3Images.imageLarge,
    md: heroImages.step3Images.imageMedium,
    sm: heroImages.step3Images.imageSmall,
    xs: heroImages.step3Images.imageExtraSmall,
    xss: heroImages.step3Images.imageExtraExtraSmall,
  },
  4: {
    lg: heroImages.step4Images.imageLarge,
    md: heroImages.step4Images.imageMedium,
    sm: heroImages.step4Images.imageSmall,
    xs: heroImages.step4Images.imageExtraSmall,
    xss: heroImages.step4Images.imageExtraExtraSmall,
  },
  5: {
    lg: heroImages.step5Images.imageLarge,
    md: heroImages.step5Images.imageMedium,
    sm: heroImages.step5Images.imageSmall,
    xs: heroImages.step5Images.imageExtraSmall,
    xss: heroImages.step5Images.imageExtraExtraSmall,
  },
  6: {
    lg: heroImages.step6Images.imageLarge,
    md: heroImages.step6Images.imageMedium,
    sm: heroImages.step6Images.imageSmall,
    xs: heroImages.step6Images.imageExtraSmall,
    xss: heroImages.step6Images.imageExtraExtraSmall,
  },
  7: {
    lg: heroImages.step7Images.imageLarge,
    md: heroImages.step7Images.imageMedium,
    sm: heroImages.step7Images.imageSmall,
    xs: heroImages.step7Images.imageExtraSmall,
    xss: heroImages.step7Images.imageExtraExtraSmall,
  },
};

export const LEARNING_TYPE_BOOT_CAMP = 'Boot Camps';

export const NAVIGATION_DELAY = 300;
