const interestedInCertificateFieldMapping = new Map([
  ['no-certificate', ['course']],
  ['certificate', ['course', 'boot-camp', 'executive-education', 'program']],
  ['accredited-degree', ['degree-program']],
]);

const learningTypeFieldMapping = new Map([
  ['Courses', ['course', 'program', 'XSeries']],
  ['Boot Camps', ['boot-camp']],
  ['Degrees', ['program', 'degree-program', 'Bachelors', 'Masters', 'MicroBachelors', 'MicroMasters', 'Professional Certificate', 'Certificate', 'License']],
  ['Executive Education', ['executive-education']],
]);

export { interestedInCertificateFieldMapping, learningTypeFieldMapping };
