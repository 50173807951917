import React, { useEffect } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

import './index.scss';
import { LoadingView } from './components/loading-ui/index';
import Quiz from './components/quiz';
import { stepToImageMapping } from './data/constants';
import useSubjectsList from './data/hooks/useSubjectsList';
import { cacheImages } from './data/quiz-utils';
import { STEP1 } from '../../data/constants';

const queryClient = new QueryClient();

const QuizContainer = () => {
  const { subjectsList, subjectsLoading } = useSubjectsList();

  useEffect(() => {
    cacheImages(stepToImageMapping[STEP1]);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {subjectsLoading && <LoadingView />}
      {!subjectsLoading && (
        <Quiz
          subjectsList={subjectsList}
        />
      )}
    </QueryClientProvider>
  );
};

export default QuizContainer;
