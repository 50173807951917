import React from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import { breakpoints } from '@openedx/paragon';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import messages from './messages';
import backgroundImage from '../../../../assets/loader/accountCreationLoaderAsset.svg';
import { HEADER_LIGHT } from '../../../../data/constants';
import { LogoOnlyHeader } from '../../../../header';
import './index.scss';

/**
 * Full page loading screen for when a user is waiting for
 * their account to be created on edX.
 */
const AccountCreationLoader = () => {
  const { formatMessage } = useIntl();
  const isDesktop = useMediaQuery({ minWidth: breakpoints.medium.minWidth + 1 });

  return (
    <div className="d-flex flex-column vh-100">
      <LogoOnlyHeader variant={HEADER_LIGHT} />
      <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center m-3.5">
        <img src={backgroundImage} alt="" className="create-account__img" />
        <h1 className={classNames(
          'text-primary-700 position-relative text-center',
          { 'display-1': isDesktop },
        )}
        >
          {formatMessage(messages.accountCreationHeading1)}
          <span className="text-brand-500 mr-1">{formatMessage(messages.accountCreationHeading2)}</span>
          <span className="create-account__loading-dots position-absolute" />
        </h1>
      </div>
    </div>
  );
};

export default AccountCreationLoader;
