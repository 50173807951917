import imageExtraExtraSmall from './edX_match-quiz_V5_375x200.jpg';
import imageExtraSmall from './edX_match-quiz_V5_465x200.jpg';
import imageSmall from './edX_match-quiz_V5_708x240.jpg';
import imageLarge from './edX_match-quiz_V5_720x768.jpg';
import imageMedium from './edX_match-quiz_V5_952x240.jpg';

export default {
  imageLarge,
  imageMedium,
  imageSmall,
  imageExtraSmall,
  imageExtraExtraSmall,
};
