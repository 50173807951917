import React, { useEffect, useMemo } from 'react';

import { getCountryList, getLocale, useIntl } from '@edx/frontend-platform/i18n';
import {
  Form,
  FormAutosuggest, FormAutosuggestOption, FormControlFeedback,
} from '@openedx/paragon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { COUNTRY_CODE_KEY, COUNTRY_DISPLAY_KEY } from './data/constants';
import { getLocationBasedUserCountry } from './data/utils';
import messages from './messages';

const CountryField = (props) => {
  const { formatMessage } = useIntl();
  const { country, onChange, onFocus } = props;
  const countryList = useMemo(() => getCountryList(getLocale()), []);

  useEffect(() => {
    if (!country.countryCode && !country.displayValue) {
      const selectedCountryObj = getLocationBasedUserCountry(countryList);
      if (selectedCountryObj) {
        const countryCode = selectedCountryObj[COUNTRY_CODE_KEY];
        const countryDisplayValue = selectedCountryObj[COUNTRY_DISPLAY_KEY];
        onChange({ target: { name: 'country', value: { countryCode, displayValue: countryDisplayValue } } });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCountryCode = (value) => {
    let countryCode = '';

    if (value) {
      const normalizedValue = value.toLowerCase();
      // Handling a case here where user enters a valid country code that needs to be
      // evaluated and set its value as a valid value.
      const selectedCountry = countryList.find(
        (c) => (
        // When translations are applied, extra space added in country value, so we should trim that.
          c[COUNTRY_DISPLAY_KEY].toLowerCase().trim() === normalizedValue
        || c[COUNTRY_CODE_KEY].toLowerCase().trim() === normalizedValue
        ),
      );
      if (selectedCountry) {
        countryCode = selectedCountry[COUNTRY_CODE_KEY];
      }
    }
    return countryCode;
  };

  const handleChange = (value) => {
    onChange({ target: { name: 'country', value: { countryCode: getCountryCode(value), displayValue: value } } });
  };

  const handleFocus = (event) => onFocus(event);

  const handleSelected = (value) => {
    onChange({ target: { name: 'country', value: { countryCode: getCountryCode(value), displayValue: value } } });
    onFocus({ target: { name: 'country', value: { countryCode: getCountryCode(value), displayValue: value } } });
  };

  const getCountriesListToDisplay = () => countryList.map((c) => (
    <FormAutosuggestOption key={c[COUNTRY_CODE_KEY]}>
      {c[COUNTRY_DISPLAY_KEY]}
    </FormAutosuggestOption>
  ));

  return (
    <div className={props.className}>
      <Form.Label className="h4 text-gray-700">{formatMessage(messages.countryFieldLabel)}</Form.Label>
      <FormAutosuggest
        name="country"
        placeholder={formatMessage(messages.countryFieldPlaceholder)}
        value={country.displayValue || ''}
        className={classNames({ 'form-field-error': props.errorMessage })}
        autoComplete="country"
        // Event Handlers
        onFocus={handleFocus}
        onSelected={(value) => handleSelected(value)}
        onChange={(value) => handleChange(value)}
      >
        {getCountriesListToDisplay()}
      </FormAutosuggest>
      {props.errorMessage && (
        <FormControlFeedback className="text-brand-500 mt-n2.5" feedback-for="country" type="invalid" hasIcon={false}>
          {props.errorMessage}
        </FormControlFeedback>
      )}
    </div>
  );
};

CountryField.propTypes = {
  errorMessage: PropTypes.string,
  country: PropTypes.shape({
    displayValue: PropTypes.string,
    countryCode: PropTypes.string,
  }),
  className: PropTypes.string,
  // Handlers
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
};

CountryField.defaultProps = {
  className: '',
  errorMessage: null,
  country: {
    displayValue: PropTypes.string,
    countryCode: PropTypes.string,
  },
};

export default CountryField;
