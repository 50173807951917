import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  quizMatchHeaderTitle: {
    id: 'quiz.match.header.title',
    defaultMessage: 'Let edX be your guide',
    description: 'Title for the quiz match feature',
  },
  quizMatchHeaderSubheading: {
    id: 'quiz.match.header.subheading',
    defaultMessage: 'Kick off your learning journey with personalized recommendations',
    description: 'Subheading to the quiz match title in the header component',
  },
});

export default messages;
