import { LEARNING_TYPE_BOOT_CAMP } from './constants';
import {
  allDegreesExceptBachelors,
  levelOfEducationWithOnlyBachelorsDegree,
  matchQuizProductsMapping,
} from './product-mappings';
import { questions } from './question-set';
import { DEFAULT_REDIRECT_URL } from '../../../data/constants';
import { getUserLocation, LOCATION_USA } from '../../../data/location';

export const getRegionBasedLearningTypes = (learningTypes) => {
  const userLocation = getUserLocation();
  if (userLocation && userLocation !== LOCATION_USA) {
    return learningTypes.filter(type => type !== LEARNING_TYPE_BOOT_CAMP);
  }
  return learningTypes;
};

export const convertObjectToQueryParams = (object) => {
  const params = new URLSearchParams(window.location.search);

  Object.entries(object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => params.append(key, item));
    } else {
      params.append(key, value);
    }
  });

  const queryString = params.toString();
  return queryString;
};

export const parseSubjectsFromAlgoliaResults = (subjectsList) => (
  subjectsList.map(subject => ({ label: subject.name }))
);

export const getQuizMatchRedirectionURL = (quizFormData) => {
  const params = {
    subject: quizFormData.subject,
    skill: quizFormData.skillLevel,
  };
  let learningTypeParams = matchQuizProductsMapping.get(quizFormData.learningType);

  // Check if levelOfEducation allows only Bachelors degree
  if (levelOfEducationWithOnlyBachelorsDegree.includes(quizFormData.levelOfEducation)) {
    learningTypeParams = learningTypeParams.filter(
      (product) => !allDegreesExceptBachelors.includes(product),
    );
  }

  if (learningTypeParams.length > 0) {
    params['learning-type'] = learningTypeParams;
    params['learning-type-badge'] = quizFormData.learningType;
  }

  const searchParams = convertObjectToQueryParams(params);
  const quizMatchSearchParams = `?quiz_match=true&${searchParams}`;
  const paramsWithPrefix = Array.from(searchParams).length ? quizMatchSearchParams : '';
  return `${DEFAULT_REDIRECT_URL}/recommendations${paramsWithPrefix}`;
};

export const cacheImages = async (images) => {
  const imagePromises = Object.values(images).map((src) => new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = resolve();
    image.onerror = reject();
  }));
  await Promise.all(imagePromises);
};

/**
 * This will filter learning type based on location and level of education
 * If location is non-US, it will remove Boot camps
 * Bachelors degree will be added or removed based on level of education. more details in product-mappings.js
 * @param learningTypes
 * @param levelOfEducation
 * @returns {list}
 */
export const getFilteredLearningTypes = (learningTypes, levelOfEducation) => {
  const learningTypeFieldOptions = questions.learningType.options.map((option) => option.label);

  // Getting mapped products for each learning type option and adding it to final list
  // if any of the mapped products exist in the input learningTypes.
  const filteredLearningTypes = learningTypeFieldOptions
    .filter((option) => {
      // Getting mapped products for each learning type option
      let mappedLearningTypeProducts = matchQuizProductsMapping.get(option);

      // Check if levelOfEducation allows only Bachelors degree
      if (levelOfEducationWithOnlyBachelorsDegree.includes(levelOfEducation)) {
        mappedLearningTypeProducts = mappedLearningTypeProducts.filter(
          (product) => !allDegreesExceptBachelors.includes(product),
        );
      }

      return learningTypes.some((type) => mappedLearningTypeProducts.includes(type));
    });

  // filter based on location
  const regionBasedLearningTypes = getRegionBasedLearningTypes(filteredLearningTypes);

  return regionBasedLearningTypes.map(type => ({ label: type }));
};
