import { parse } from 'query-string';

import { getUserLocation, LOCATION_USA } from '../../../../data/location';
import quizMessages from '../../components/quizes/messages';

export const LEARNING_TYPE_BOOT_CAMP = 'Boot Camps';

export const getRegionBasedLearningTypes = (learningTypes) => {
  const userLocation = getUserLocation();
  if (userLocation && userLocation !== LOCATION_USA) {
    return learningTypes.filter(type => type !== LEARNING_TYPE_BOOT_CAMP);
  }
  return learningTypes;
};

export const getQueryParams = () => parse(window.location.search);

export const convertObjectToQueryParams = (object) => {
  const params = new URLSearchParams(window.location.search);

  Object.entries(object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => params.append(key, item));
    } else {
      params.append(key, value);
    }
  });

  const queryString = params.toString();
  return queryString;
};

export const validateSubject = (value, subjectsList, formatMessage) => {
  let error = '';
  if (!value) {
    error = formatMessage(quizMessages.subjectEmptyFieldError);
  } else if (!subjectsList.includes(value)) {
    error = formatMessage(quizMessages.subjectInvalidValueFieldError, { value });
  }
  return error;
};

export const validateSubjectOnChange = (value, subjectsList, formatMessage) => {
  let error = '';
  if (value.trim().length > 0) {
    const validOption = subjectsList.filter((subject) => (subject.toLowerCase().includes(value.toLowerCase())));
    if (validOption.length === 0) {
      error = formatMessage(quizMessages.subjectInvalidValueFieldError, { value });
    }
  }
  return error;
};

export const parseSubjectsFromAlgoliaResults = (subjectsList) => subjectsList.map(subject => subject.name);
