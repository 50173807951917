import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  defaultDropdownOptionLabel: {
    id: 'title.default.dropdown.option',
    defaultMessage: 'Select the option',
    description: 'Label for default disabled dropdown select option',
  },
});

export default messages;
