import React from 'react';

import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';

import './index.scss';
import messages from './messages';

const LargeLayout = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="pl-3 py-2 bg-primary-500">
      <h1 className="h3 mt-2 d-flex logo-container">
        <span className="text-white">
          <img src={getConfig().LOGO_WHITE_URL} alt="edx logo" className="d-flex align-items-center justify-content-center flex-shrink-0 header__logo mr-1" />
        </span>
        <span className="text-accent-b">
          {formatMessage(messages.quizMatchHeaderTitle)}
        </span>
      </h1>
    </div>
  );
};

export default LargeLayout;
