import React, { useEffect, useState } from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import {
  Button, Container, Form, Stepper,
} from '@openedx/paragon';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

import {
  BACKEND_ERRORS_KEYS,
  ITERATION_2,
  QUIZ_FIELD_MAPPING_ITERATION_2 as QUIZ_FIELD_MAPPING,
  STEP1,
  STEP2,
  STEP3,
  STEP4,
  STEP5,
  STEP6,
  STEP7,
} from '../../../../data/constants';
import { getUsernameSuggestions, registerRequest } from '../../../../data/service';
import {
  trackPiiQuestionsViewedEvent,
  trackQuizMatchPreviousButtonClicked,
  trackQuizMatchSubmitButtonClicked,
  trackQuizQuestionViewedEvent,
  trackQuizSubmittedEvent,
} from '../../../../data/track';
import {
  CountryField,
  EmailField,
  FormGroup,
  PasswordField,
  TermsOfServiceAndHonorCode,
  UsernameField,
} from '../../../../form-fields';
import SelectableBoxField from '../../../../form-fields/selectable-box-field/SelectableBoxField';
import { NAVIGATION_DELAY } from '../../data/constants';
import useAvailableLearningTypes from '../../data/hooks/useAvailableLearningTypes';
import messages from '../../data/messages';
import { NUM_OF_STEPS, questions } from '../../data/question-set';
import { getQuizMatchRedirectionURL } from '../../data/quiz-utils';
import validateField from '../../data/quiz-validator';
import BaseComponent from '../base-component';
import { AccountCreationLoader, QuestionLoadingView } from '../loading-ui/index';
import QuizProgressBar from '../progress-bar';

const Quiz = ({ subjectsList }) => {
  const { formatMessage } = useIntl();

  const registrationMutation = useMutation(registerRequest);
  const usernameSuggestionMutation = useMutation(getUsernameSuggestions);

  const [currentStep, setCurrentStep] = useState(STEP1);
  const [usernameSuggestions, setUsernameSuggestions] = useState([]);
  const [quizFormData, setQuizFormData] = useState({
    subject: '',
    skillLevel: '',
    learningType: '',
    levelOfEducation: '',
    workExperience: '',
    name: '',
    country: { countryCode: '', displayValue: '' },
    email: '',
    username: '',
    password: '',
    marketingEmailsOptIn: true,
  });
  const [quizFormErrors, setQuizFormErrors] = useState({
    name: '',
    email: '',
    username: '',
    password: '',
    country: '',
  });

  const {
    learningTypes,
    learningTypesLoading,
  } = useAvailableLearningTypes(quizFormData.subject, quizFormData.levelOfEducation);

  const eventProps = {
    iteration: ITERATION_2,
    organic_traffic: false,
    experiment: null,
    variation: null,
  };

  useEffect(() => {
    if (usernameSuggestionMutation.isSuccess) {
      setUsernameSuggestions(usernameSuggestionMutation.data?.fieldValidations.username_suggestions || []);
    }
  }, [usernameSuggestionMutation.isSuccess, usernameSuggestionMutation.data]);

  useEffect(() => {
    if (registrationMutation.isError && registrationMutation.error?.customAttributes?.httpErrorResponseData) {
      const errorResponseData = registrationMutation.error?.customAttributes?.httpErrorResponseData;
      const backendFieldValidationErrors = JSON.parse(errorResponseData);
      const validations = {};
      Object.keys(backendFieldValidationErrors).forEach((key) => {
        if (BACKEND_ERRORS_KEYS.includes(key)) {
          validations[key] = backendFieldValidationErrors[key][0].user_message;
        }
      });
      setQuizFormErrors(prevState => ({ ...prevState, ...validations }));
    }
  }, [registrationMutation.isError, registrationMutation.error]);

  useEffect(() => {
    const stepQuestion = QUIZ_FIELD_MAPPING[`STEP${currentStep}`][0];
    const currentQuestion = questions[stepQuestion];
    if (currentStep <= STEP5) {
      const eventProperties = {
        ...eventProps,
        position: currentStep,
        label: currentQuestion.label,
        text: currentQuestion.text,
        type: currentQuestion.type,
      };
      trackQuizQuestionViewedEvent(eventProperties);
    } else {
      const eventProperties = {
        ...eventProps,
        position: currentStep,
      };
      trackPiiQuestionsViewedEvent(eventProperties);
    }
  }, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  if (registrationMutation.isSuccess) {
    const redirectUrl = getQuizMatchRedirectionURL(quizFormData);

    trackQuizSubmittedEvent({ ...quizFormData, ...eventProps }, redirectUrl);
  }

  const handleSubmit = () => {
    trackQuizMatchSubmitButtonClicked({
      iteration: ITERATION_2,
      organic_traffic: false,
    });

    const { isValid, validations } = validateField(
      quizFormData, quizFormErrors, QUIZ_FIELD_MAPPING.STEP7, formatMessage, usernameSuggestions.length,
    );

    if (!isValid) {
      setQuizFormErrors(prevState => ({ ...prevState, ...validations }));
    } else {
      const payload = {
        country: quizFormData.country?.countryCode,
        email: quizFormData.email,
        honor_code: true,
        marketing_emails_opt_in: quizFormData.marketingEmailsOptIn,
        name: quizFormData.name,
        password: quizFormData.password,
        username: quizFormData.username,
        // optional fields
        level_of_education: quizFormData.levelOfEducation,
      };
      registrationMutation.mutate(payload);
    }
  };

  const handleNext = () => {
    if (currentStep > STEP5) {
      const { isValid, validations } = validateField(
        quizFormData, quizFormErrors, QUIZ_FIELD_MAPPING[`STEP${currentStep}`], formatMessage,
      );

      if (!isValid) {
        setQuizFormErrors(prevState => ({ ...prevState, ...validations }));
        return;
      }
    }

    setCurrentStep(prevState => prevState + 1);
  };

  const handleBackBtnClick = () => {
    trackQuizMatchPreviousButtonClicked({ position: currentStep });
    setCurrentStep(prevState => prevState - 1);
  };

  const handleSelect = (event) => {
    const { name, value } = event.target;
    setQuizFormData(prevState => ({ ...prevState, [name]: value }));
    setTimeout(() => setCurrentStep(prevState => prevState + 1), NAVIGATION_DELAY);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setQuizFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleFocus = (event) => {
    const { name } = event.target;
    setQuizFormErrors(prevState => ({ ...prevState, [name]: '' }));
  };

  const generateUsernameSuggestions = (event) => {
    const { name, value } = event.target;
    if (!quizFormData.username && value) {
      const payload = {
        form_field_key: name,
        name: value,
      };
      usernameSuggestionMutation.mutate(payload);
    }
  };

  const handleUsernameSuggestionClosed = () => {
    setUsernameSuggestions([]);
  };

  if (registrationMutation.isLoading || registrationMutation.isSuccess) {
    return (
      <AccountCreationLoader />
    );
  }

  if (learningTypesLoading && currentStep === STEP4) {
    return (
      <QuestionLoadingView />
    );
  }

  return (
    <BaseComponent currentStep={currentStep} backStepHandler={handleBackBtnClick}>
      <Stepper activeKey={`step${currentStep}`}>
        <Container
          className="pb-2.5 px-2.5 d-flex flex-column flex-grow-1 quiz-container"
        >
          <QuizProgressBar currentStep={currentStep} totalSteps={NUM_OF_STEPS} />
          <Stepper.Step title="" eventKey={`step${STEP1}`}>
            <SelectableBoxField
              fieldMessages={messages}
              name={questions.subject.name}
              value={quizFormData.subject}
              keepTwoColsInExtraSmall
              label={formatMessage(messages.subjectFieldLabel)}
              options={subjectsList}
              onSelect={handleSelect}
            />
          </Stepper.Step>

          <Stepper.Step title="" eventKey={`step${STEP2}`}>
            <SelectableBoxField
              fieldMessages={messages}
              name={questions.skillLevel.name}
              value={quizFormData.skillLevel}
              label={formatMessage(messages.skillLevelFieldLabel)}
              options={questions.skillLevel.options}
              onSelect={handleSelect}
            />
          </Stepper.Step>

          <Stepper.Step title="" eventKey={`step${STEP3}`}>
            <SelectableBoxField
              fieldMessages={messages}
              name={questions.levelOfEducation.name}
              value={quizFormData.levelOfEducation}
              label={formatMessage(messages.levelOfEducationFieldLabel)}
              options={questions.levelOfEducation.options}
              onSelect={handleSelect}
            />
          </Stepper.Step>

          <Stepper.Step title="" eventKey={`step${STEP4}`}>
            <SelectableBoxField
              fieldMessages={messages}
              name={questions.learningType.name}
              value={quizFormData.learningType}
              label={formatMessage(messages.learningTypeFieldLabel)}
              options={learningTypes}
              onSelect={handleSelect}
            />
          </Stepper.Step>

          <Stepper.Step title="" eventKey={`step${STEP5}`}>
            <SelectableBoxField
              fieldMessages={messages}
              name={questions.workExperience.name}
              value={quizFormData.workExperience}
              label={formatMessage(messages.workExperienceFieldLabel)}
              options={questions.workExperience.options}
              onSelect={handleSelect}
            />
          </Stepper.Step>

          <Stepper.Step title="" eventKey={`step${STEP6}`}>
            <h2 className="mb-3.5 mb-lg-4.5 px-1 text-gray-700 field__question-heading">
              {formatMessage(messages.userProfilePageTitle)}
            </h2>
            <Form className="overflow flex-grow-1 p-1">
              <FormGroup
                name="name"
                value={quizFormData.name}
                label={formatMessage(messages.nameFieldLabel)}
                errorMessage={quizFormErrors.name}
                className="mb-lg-4"
                autoComplete="given-name"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={generateUsernameSuggestions}
              />
              <CountryField
                country={quizFormData.country}
                errorMessage={quizFormErrors.country}
                className="mb-0"
                onChange={handleChange}
                onFocus={handleFocus}
              />
              <div className="d-flex justify-content-end mt-lg-4">
                <Button onClick={handleNext} variant="brand">
                  {formatMessage(messages.nextStepButton)}
                </Button>
              </div>
            </Form>
          </Stepper.Step>

          <Stepper.Step title="" eventKey={`step${STEP7}`}>
            <h2 className="mb-3.5 mb-lg-4.5 px-1 text-gray-700 field__question-heading">
              {formatMessage(messages.registrationPageTitle)}
            </h2>
            <Form className="overflow-auto p-1">
              <EmailField
                value={quizFormData.email}
                errorMessage={quizFormErrors.email}
                className="mb-lg-4"
                onChange={handleChange}
                onFocus={handleFocus}
              />
              <UsernameField
                name="username"
                value={quizFormData.username}
                label={formatMessage(messages.usernameFieldLabel)}
                errorMessage={quizFormErrors.username}
                className="mb-lg-4"
                usernameSuggestions={usernameSuggestions}
                onChange={handleChange}
                onFocus={handleFocus}
                handleUsernameSuggestionClose={handleUsernameSuggestionClosed}
              />
              <PasswordField
                value={quizFormData.password}
                errorMessage={quizFormErrors.password}
                className="mb-lg-4"
                handleChange={handleChange}
                handleFocus={handleFocus}
              />
              <FormGroup
                name="marketingEmailsOptIn"
                value={quizFormData.marketingEmailsOptIn}
                label={formatMessage(messages.marketingEmailsOptInLabel)}
                type="checkbox"
                onChange={handleChange}
              />
              <TermsOfServiceAndHonorCode />
              <div className="d-flex justify-content-end mt-lg-4 mt-3">
                <Button onClick={handleSubmit} variant="brand">
                  {formatMessage(messages.seeRecommendationsButton)}
                </Button>
              </div>
            </Form>
          </Stepper.Step>
        </Container>
      </Stepper>
    </BaseComponent>
  );
};

Quiz.propTypes = {
  subjectsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Quiz;
