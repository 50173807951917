import React, { useEffect, useRef, useState } from 'react';

import { sendPageEvent, sendTrackEvent } from '@edx/frontend-platform/analytics';
import { useIntl } from '@edx/frontend-platform/i18n';
import {
  Collapsible, Form, Menu, MenuItem,
} from '@openedx/paragon';
import PropTypes from 'prop-types';
import { useClickOutside } from 'react-click-outside-hook';

import './index.scss';
import messages from './messages';

const MultiSelectDropdown = ({ label, options }) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const menuRef = useRef();

  useClickOutside(menuRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  useEffect(() => {
    sendPageEvent('multi_select_page', 'multi-select');
    sendTrackEvent('edx.bi.multi_select.viewed', { category: 'user-engagement' });
  }, []);

  const handleChange = e => {
    if (e.target.checked) {
      setSelectedOptions([...selectedOptions, e.target.value]);
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <Form.Group>
      <Form.Label>
        <h4>
          {label}
        </h4>
      </Form.Label>
      <div ref={menuRef}>
        <Collapsible onToggle={handleToggle} open={open} title={formatMessage(messages.defaultDropdownOptionLabel)}>
          {open && (
            <Menu>
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  className="mr-2"
                  id={`${option}-${index}`}
                  as={Form.Checkbox}
                  value={option}
                  onChange={handleChange}
                >
                  <span className="mr-2">{option}</span>
                </MenuItem>
              ))}
            </Menu>
          )}
        </Collapsible>
      </div>
    </Form.Group>
  );
};

MultiSelectDropdown.defaultProps = {
  label: '',
  options: [],
};

MultiSelectDropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default MultiSelectDropdown;
