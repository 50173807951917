import React from 'react';

import './index.scss';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Button } from '@openedx/paragon';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { STEP1, STEP7 } from '../../../../data/constants';
import messages from '../../data/messages';

const LeftBannerLayout = ({
  backStepHandler, currentStep, heading, leftBannerColor, leftBannerImage, stepsImageText,
}) => {
  const { formatMessage } = useIntl();

  if (heading) {
    return (
      <div className="large-layout left-banner w-50 bg-light-200 d-flex flex-column justify-content-center">
        <h1 className="text-primary-500 w-75 display-1 ml-5">
          {heading}
        </h1>
      </div>
    );
  }

  return (
    <div className="large-layout left-banner w-50" style={{ backgroundColor: leftBannerColor }}>
      <img
        className={classNames('h-100 w-100 left-banner__background-img', {
          'ml-n4.5': ![STEP1, STEP7].includes(currentStep),
          'left-banner__background-img-with-color': currentStep === STEP7,
        })}
        style={{ objectPosition: [STEP1, STEP7].includes(currentStep) ? 'left' : 'center' }}
        src={leftBannerImage}
        alt={stepsImageText}
      />
      {backStepHandler && (
        <Button className="quiz__back-btn" variant="outline-dark" onClick={backStepHandler}>
          {formatMessage(messages.backStepButton)}
        </Button>
      )}
    </div>
  );
};

LeftBannerLayout.propTypes = {
  leftBannerColor: PropTypes.string,
  leftBannerImage: PropTypes.string.isRequired,
  heading: PropTypes.element,
  backStepHandler: PropTypes.func,
  currentStep: PropTypes.number.isRequired,
  stepsImageText: PropTypes.string.isRequired,
};

LeftBannerLayout.defaultProps = {
  heading: null,
  leftBannerColor: '',
  backStepHandler: null,
};

export default LeftBannerLayout;
