import { useEffect, useState } from 'react';

import algoliasearchHelper from 'algoliasearch-helper';

import initializeSearchClient from '../../../../data/algolia';
import { getFilteredLearningTypes } from '../quiz-utils';

const PRODUCT_INDEX = 'product';
const SUBJECT_FACET = 'subject';
const LEARNING_TYPE_FACET = 'learning_type';

/**
 * This hook handles the retrieval and filtering of available learning types.
 * When the 'subject' is selected, it retrieves all available learning types
 * and keeps the loading state active. Once 'levelOfEducation' is selected by
 * the user, it filters the learning types based on the selected level of
 * education and updates the loading state to indicate that the filtering process
 * is complete. The final loading state is used to represent the overall loading
 * status of the learning types.
 * @param subject
 * @param levelOfEducation
 * @returns {{learningTypes: [], learningTypesLoading: boolean}}
 */
const useAvailableLearningTypes = (subject, levelOfEducation) => {
  const [learningTypes, setLearningTypes] = useState([]);
  const [learningTypesLoading, setLearningTypesLoading] = useState(true);
  const [learningTypesFiltering, setLearningTypesFiltering] = useState(true);

  useEffect(() => {
    if (subject === '') {
      return;
    }

    const algoliaSearchParams = {
      facets: [SUBJECT_FACET],
      disjunctiveFacets: [LEARNING_TYPE_FACET],
    };

    const searchClient = initializeSearchClient();
    const searchHelper = algoliasearchHelper(
      searchClient,
      PRODUCT_INDEX,
      algoliaSearchParams,
    );

    searchHelper.addFacetRefinement(SUBJECT_FACET, subject);

    const searchIndex = () => {
      setLearningTypesLoading(true);
      searchHelper.search();
    };

    searchIndex();

    searchHelper.on('result', ({ results }) => {
      const availableLearningTypes = results.getFacetValues(LEARNING_TYPE_FACET).map((facet) => facet.name);
      setLearningTypes(availableLearningTypes);
      setLearningTypesLoading(false);
    });

    searchHelper.on('error', () => setLearningTypesLoading(false));
  }, [subject]);

  // This hook waits for the selection of 'levelOfEducation' and keeps the loading state
  // active until the filtering process is complete. Once the user selects a level of
  // education, it filters the learning types accordingly and updates the loading state
  // to indicate that the filtering is finished. The final loading state, represented by
  // 'learningTypesFiltering,' reflects the overall loading status of the learning types.
  useEffect(() => {
    if (levelOfEducation === '' || learningTypesLoading) {
      return;
    }

    const filteredLearningTypes = getFilteredLearningTypes(learningTypes, levelOfEducation);
    setLearningTypes(filteredLearningTypes);
    setLearningTypesFiltering(false);
  }, [levelOfEducation, learningTypesLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    learningTypes,
    learningTypesLoading: learningTypesFiltering,
  };
};

export default useAvailableLearningTypes;
