const NUM_OF_STEPS = 7;
const questions = {
  subject: {
    name: 'subject',
    label: 'subject',
    text: 'What field are you interested in?',
    type: 'select',
  },
  skillLevel: {
    name: 'skillLevel',
    label: 'current_skill_level',
    text: 'What is your current level in your selected field?',
    type: 'select',
    options: [
      'no-education-and-no-work-experience',
      'has-education-and-no-work-experience',
      'no-education-and-has-work-experience',
      'has-education-and-has-work-experience',
    ],
  },
  learningType: {
    name: 'learningType',
    label: 'learning_type',
    text: 'What type of experience are you interested in? This will help us hone in on our most relevant options to your goals.',
    type: 'select',
    options: [
      'Courses',
      'Boot Camps',
      'Degrees',
      'Executive Education',
      'unsure',
    ],
  },
  levelOfEducation: {
    name: 'levelOfEducation',
    label: 'level_of_education',
    text: 'What is your highest level of education completed? This will help us show you offerings that you qualify for.',
    type: 'select',
    options: [
      'none',
      'jhs',
      'hs',
      'a',
      'b',
      'm',
      'p',
      'other',
    ],
  },
  workExperience: {
    name: 'workExperience',
    label: 'work_experience',
    text: 'How many years of work experience do you have?',
    type: 'select',
    options: [
      '0yrs',
      '1-5yrs',
      '6-10yrs',
      '11-15yrs',
      '16-20yrs',
      '20+yrs',
    ],
  },
  gender: {
    name: 'gender',
    options: [
      'm',
      'f',
      'o',
    ],
  },
};

export { NUM_OF_STEPS, questions };
