import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  defaultDropdownPlaceholder: {
    id: 'default.dropdown.placeholder',
    defaultMessage: 'Select an option',
    description: 'Default placeholder for the dropdown field',
  },
});

export default messages;
