const updatePathWithQueryParams = (path) => {
  const queryParams = window.location.search;

  if (!queryParams) {
    return path;
  }

  return `${path}${queryParams}`;
};

export default updatePathWithQueryParams;
