import { defineMessages } from '@edx/frontend-platform/i18n';

const messages = defineMessages({
  previousStepButton: {
    id: 'previous.step.button',
    defaultMessage: 'Previous',
    description: 'Button that sends the user to the previous step in the skills builder.',
  },
  nextStepButton: {
    id: 'next.step.button',
    defaultMessage: 'Next',
    description: 'Button that sends the user to the next step in the skills builder.',
  },
  showMeMyRecommendationsButton: {
    id: 'show.me.my.recommendations',
    defaultMessage: 'Show me my recommendations',
    description: 'Button that submits the form and sends the user to the recommendations page.',
  },
  generatingYourRecommendations: {
    id: 'generating.your.recommendations',
    defaultMessage: 'Generating your recommendations',
    description: 'Text on the submission button when the user is in loading state and waiting for recommendtions',
  },
});

export default messages;
