const NUM_OF_STEPS = 7;
const questions = {
  subject: {
    name: 'subject',
    label: 'subject',
    text: 'What field are you interested in?',
    type: 'select',
  },
  learningGoal: {
    name: 'learningGoal',
    label: 'learning_goal',
    text: 'What goal is most important to you?',
    type: 'select',
    options: [
      'change-career',
      'advance-career',
      'explore-new-skills',
      'other',
      'unsure',
    ],
  },
  levelOfEducation: {
    name: 'levelOfEducation',
    label: 'level_of_education',
    text: 'What is your highest level of education completed? This will help us show you offerings that you qualify for.',
    type: 'select',
    options: [
      'none',
      'jhs',
      'hs',
      'a',
      'b',
      'm',
      'p',
      'other',
    ],
  },
  workExperience: {
    name: 'workExperience',
    label: 'work_experience',
    text: 'How many years of work experience do you have?',
    type: 'select',
    options: [
      '0yrs',
      '1-5yrs',
      '6-10yrs',
      '11-15yrs',
      '16-20yrs',
      '20+yrs',
    ],
  },
  interestedInCertificate: {
    name: 'interestedInCertificate',
    label: 'interested_in_certificate',
    text: 'What kind of certificate or degree upon completion would benefit you the most?',
    type: 'select',
    options: [
      'no-certificate',
      'certificate',
      'accredited-degree',
      'unsure',
    ],
  },
  gender: {
    name: 'gender',
    options: [
      'm',
      'f',
      'o',
    ],
  },
};

export { NUM_OF_STEPS, questions };
