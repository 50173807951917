import React from 'react';

import { getConfig } from '@edx/frontend-platform';
import { Hyperlink } from '@openedx/paragon';
import PropTypes from 'prop-types';

import './index.scss';
import { HEADER_LIGHT, HEADER_PRIMARY_GRADIENT } from '../../../data/constants';

const LogoOnlyHeader = ({ variant }) => (
  <header className={`py-2.5 bg-${variant}`} aria-label="Main">
    <Hyperlink destination={getConfig().MARKETING_SITE_BASE_URL} aria-label="edX home">
      <img
        src={variant === HEADER_PRIMARY_GRADIENT ? getConfig().LOGO_WHITE_URL : getConfig().LOGO_URL}
        alt="edx logo"
        className="align-items-center justify-content-center flex-shrink-0 logo-only-header__logo"
      />
    </Hyperlink>
  </header>
);

LogoOnlyHeader.propTypes = {
  variant: PropTypes.oneOf([HEADER_PRIMARY_GRADIENT, HEADER_LIGHT]),
};

LogoOnlyHeader.defaultProps = {
  variant: HEADER_PRIMARY_GRADIENT,
};

export default LogoOnlyHeader;
