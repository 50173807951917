import {
  Skeleton,
} from '@openedx/paragon';
import '../index.scss';

const DesktopLoadingView = () => (
  <div className="d-flex" data-testid="desktop-loading-view">
    <div className="w-50 left-skeleton bg-light-200 min-vh-100 d-flex flex-column justify-content-center" />
    <div className="w-50 px-5 flex-grow-1 overflow-hidden">
      <div className="pt-5.5 pb-2 mb-5 d-flex justify-content-center">
        <div className="w-100 skeleton-max-width-540">
          <Skeleton className="mb-26 bg-light-400 skeleton-max-width-327" height={8} />
          <Skeleton className="bg-light-400" count={2} height={20} width="100%" />
        </div>
      </div>
      <div className="d-flex justify-content-center desktop-skeleton-options-container">
        <div className="w-50 desktop-skeleton-options-column">
          <Skeleton className="skeleton-options" count={2} height={110} />
        </div>
        <div className="w-50 desktop-skeleton-options-column">
          <Skeleton className="skeleton-options" count={2} height={110} />
        </div>
      </div>
    </div>
  </div>
);

export default DesktopLoadingView;
