import React, { useState } from 'react';

import { useIntl } from '@edx/frontend-platform/i18n';
import { Alert, Icon } from '@openedx/paragon';
import { Close, Error } from '@openedx/paragon/icons';
import PropTypes from 'prop-types';

import { emailRegex, getSuggestionForInvalidEmail, validateEmailAddress } from './data/utils';
import messages from './messages';
import FormGroup from '../wrappers/FormGroup';

const EmailField = ({
  value, errorMessage, onChange, onFocus, className,
}) => {
  const { formatMessage } = useIntl();
  const [emailSuggestion, setEmailSuggestion] = useState({ suggestion: '', type: '' });

  const checkForSuggestions = (val) => {
    if (val && val.length > 2) {
      const [username, domainName] = val.split('@');
      // Check if email address is invalid. If we have a suggestion for invalid email
      // provide that along with the error message.
      if (!emailRegex.test(val)) {
        setEmailSuggestion({
          suggestion: getSuggestionForInvalidEmail(domainName, username),
          type: 'error',
        });
      } else {
        const response = validateEmailAddress(val, username, domainName);
        if (response.hasError) {
          delete response.hasError;
        }
        setEmailSuggestion({ ...response });
      }
    }
  };

  const handleOnBlur = (event) => {
    checkForSuggestions(event.target.value);
  };

  const handleOnChange = (event) => {
    onChange(event);
  };

  const handleOnFocus = (event) => {
    onFocus(event);
  };

  const handleSuggestionClick = (event) => {
    event.preventDefault();
    onChange({ target: { name: 'email', value: emailSuggestion.suggestion } });
    setEmailSuggestion({ suggestion: '', type: '' });
    onFocus(event);
  };

  const handleEmailSuggestionClosed = () => setEmailSuggestion({ suggestion: '', type: '' });

  const renderEmailFeedback = () => {
    if (emailSuggestion.type === 'error') {
      return (
        <Alert variant="danger" className="email-suggestion-alert-error mt-2 mr-2" icon={Error}>
          <span className="email-suggestion__text">
            {formatMessage(messages.didYouMeanAlertText)}{' '}
            <Alert.Link
              href="#"
              name="email"
              onClick={handleSuggestionClick}
            >
              {emailSuggestion.suggestion}
            </Alert.Link>
            ?
            <Icon src={Close} className="email-suggestion__close" onClick={handleEmailSuggestionClosed} tabIndex="0" />
          </span>
        </Alert>
      );
    }

    return (
      <span id="email-warning" className="small">
        {formatMessage(messages.didYouMeanAlertText)}:{' '}
        <Alert.Link
          href="#"
          name="email"
          className="email-suggestion-alert-warning"
          onClick={handleSuggestionClick}
        >
          {emailSuggestion.suggestion}
        </Alert.Link>?
      </span>
    );
  };

  return (
    <FormGroup
      name="email"
      label={formatMessage(messages.emailFieldLabel)}
      value={value}
      emailSuggestion={emailSuggestion}
      errorMessage={errorMessage}
      borderClass={emailSuggestion.type === 'warning' ? 'yellow-border' : ''}
      className={className}
      autoComplete="email"
      // Event Handlers
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      handleOnClose={handleEmailSuggestionClosed}
    >
      {emailSuggestion.suggestion ? renderEmailFeedback() : null}
    </FormGroup>
  );
};

EmailField.defaultProps = {
  errorMessage: '',
  className: '',
};

EmailField.propTypes = {
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  // Handlers
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
};

export default EmailField;
